@import (reference) "../common/const.less";

.links-are-buttons a,
.button,
.textinput,
label,
button,
input,
select,
textarea {
  box-sizing: border-box;
  font-family: @font_def;
  font-size: 1em;
}

.links-are-buttons a,
a.button,
a.button:visited,
.button,
button,
input[type="submit"],
input[type="button"] {
  border: 0;
  padding: 0.8em 1.5em;
  text-decoration: none;
  color: @c_button_txt;
  background-color: @c_button_bg;
  box-sizing: border-box;
  display: inline-block;
  margin: 0.5em 0;
  cursor: pointer;
  user-select: none;
  border-radius: @button_radius;

  &:visited {
    color: @c_button_txt;
  }

  &.gray {
    background-color: @c_base_lgray;
    color: @c_def_txt;
  }

  &:hover,
  &:focus,
  &:active {
    color: @c_button_active_txt;
    background-color: @c_button_active_bg;
  }
}

select,
textarea,
.textinput,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="url"] {
  padding: 1em;
  line-height: 1.5;
  margin: 0.5em 0;
  display: block;
  width: 100%;
  border: (2 * @pixel) solid @c_form_border;
  border-radius: @input_radius;

  .has-error & {
    border-color: @c_form_error;
  }
}

input[type="file"].form-control {
  padding: 1em 0;
  width: 100%;
}

textarea,
select[multiple] {
  height: auto;
  min-height: 10em;
}

.spacer-star,
.required-star {
  // font-family: @font_alt;
  // font-weight: @wt_alt_bold;
  visibility: hidden;
}

.required-star {

  .has-error & {
    color: @c_form_error;
    visibility: visible;
  }
}

.inputwrap {
  margin-bottom: 2em;

  label {
    display: inline-block;
    padding: 0.5em 0;
    min-width: 8em;

    &.control-label {
      font-weight: @wt_bold;
    }
  }

  .form-control {
    margin-top: 0;
  }

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  // .form-control { }

  ul.messages {
    margin: 0.2em;
    padding: 0;
    font-size: 0.875em;

    li {
      list-style-type: none;
      margin: 0 0 0.5em;
      padding: 0;

      strong {
        color: @c_form_error;
      }
    }
  }
}

.bg-offwhite {
  background-color: @c_footer_bg;
}

.form-page {
  max-width: 30em;
  margin: 0 auto;
}

input[type="text"].jscolor {
  // max-width: 9em;
  // margin-left: auto;
  // margin-right: auto;
  // display: block;
  text-align: center;
}

.form-page.form-page-wide-desc {
  max-width: 40em;
}

@media screen and (min-width: @screen_gt_large) {

  .form-page.form-page-wide {
    max-width: 64em;

    .inputwrap {
      display: flex;
      flex-direction: row;
      margin-bottom: 1em;

      .innerMessagesWrap,
      .inputInnerWrap,
      .innerLabelWrap {
        flex: 1 1 17em;
        // background-color: pink;
      }

      .inputInnerWrap {
        flex-basis: 30em;
        max-width: 30em;
        // background-color: yellow;
      }

      label.control-label {
        display: block;
        text-align: right;
        padding-top: 1em;
        padding-right: 1em;
        padding-bottom: 0;
      }

      .messages {
        padding-left: 1em;
      }

      // input[type="file"].form-control {
      //   // padding: 1em;
      //   // width: 100%;
      // }
    }
  }
}

.thank_you_bg {
  min-height: 70vh;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 70%;
  margin-bottom: -5.25em;
  padding-bottom: 1em;
}
