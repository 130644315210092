// @import "common/webfont.less";
@import "common/const.less";
@import "common/hacks.less";
@import "common/columns.less";
//
@import "web/typography.less";
@import "web/layout.less";
@import "web/footer.less";
@import "web/menu.less";
@import "web/contactforms.less";
@import "web/forms.less";
// Modules:
@import "modules/factbox.less";
@import "modules/faq.less";
@import "modules/gallery.less";
@import "modules/articles.less";
@import "modules/toc.less";
@import "modules/subsection.less";
@import "modules/reflist.less";
@import "modules/pagination.less";
@import "modules/contact.less";
@import "modules/frontpage_banner.less";
@import "modules/breadcrumbs.less";
@import "modules/table.less";
@import "modules/frontwidget.less";
@import "modules/fancylink.less";

@media screen and (max-width: @screen_lt_small) {

  .wagtail-userbar {
    display: none !important;
  }
}

@import "web/print.less";
