@import (reference) "../common/const.less";

.breadcrumb {
  margin: 0.5em 0;
  padding: 0 0em 0em 2em;
  line-height: 1.5;

  li {

    a {
      // display: block;
      font-weight: @wt_link;
    }

    list-style-type: none;
    display: inline-block;
    // &:after {
    //   content: "›";
    //   padding: 0 0.5em;
    // }

    svg.separator {
      width: 1em;
      height: 1.5em;
      vertical-align: bottom;
    }

    &:last-child {

      svg {
        display: none;
      }

      a {
        color: @c_def_txt;
        font-weight: @wt_normal;
      }
    }

    // &.active:after {
    //   display: none;
    // }
  }
}
@media screen and (max-width: @screen_lt_medium) {

  .breadcrumb {
    display: none;
  }
}
@media screen and (min-width: @screen_gt_medium) {

  .breadcrumb {
    float: left;
    margin-right: 2em;
  }
}
