@import (reference) "../common/const.less";
@colBaseUnit: 8.5%;

table {

  td,
  th {
    text-align: left;

    &.number {
      text-align: right;
    }

    &.center {
      text-align: center;
    }

    &.colw1 {
      width: ((1 * @colBaseUnit) - 2%);
    }

    &.colw2 {
      width: ((2 * @colBaseUnit) - 2%);
    }

    &.colw3 {
      width: ((3 * @colBaseUnit) - 2%);
    }

    &.colw4 {
      width: ((4 * @colBaseUnit) - 2%);
    }

    &.colw5 {
      width: ((5 * @colBaseUnit) - 2%);
    }

    &.colw6 {
      width: ((6 * @colBaseUnit) - 2%);
    }

    &.colw7 {
      width: ((7 * @colBaseUnit) - 2%);
    }

    &.colw8 {
      width: ((8 * @colBaseUnit) - 2%);
    }

    &.colw9 {
      width: ((9 * @colBaseUnit) - 2%);
    }

    &.colw10 {
      width: ((10 * @colBaseUnit) - 2%);
    }

    &.colw11 {
      width: ((11 * @colBaseUnit) - 2%);
    }
  }
}

.block-table table,
table.fancytable {
  width: 100%;
  margin: 1.5em 0;

  thead th {
    font-weight: bold;
    // color: @c_table_th_text;
    // background: @c_table_th_bg;
    border-bottom: 4px solid @c_table_th_border;
  }

  td,
  th {
    padding: 0.5em 1em;
    border: 1px solid @c_table_border;
  }
}

// .dbg {
//
//   .block-table table,
//   table.fancytable {
//
//     thead th {
//       color: @c_base_white;
//     }
//   }
//
//   tr.sum-line th {
//     border-top: 2px solid @c_table_th_border;
//     border-bottom: 6px double @c_table_th_border;
//   }
//
//   thead th {
//     border-bottom: 3px solid @c_table_th_border;
//   }
// }

.block-table table {

  th,
  td {
    // text-align: center;
  }
}

@media screen {

  .block-table table,
  table.fancytable {

    thead th {
      color: @c_table_th_text;
      background: @c_table_th_bg;
    }

    tbody {

      th {
        color: @c_table_th_text;
        background: @c_table_th_bg;
        border-right: 4px solid @c_table_th_border;
      }

      tr:nth-child(odd) th {
        background-color: @c_table_bg_oddrow_th;
      }

      tr:nth-child(even) th {
        background-color: @c_table_bg_evenrow_th;
      }
    }

    tr:nth-child(odd) td {
      background-color: @c_table_bg_oddrow;
    }

    tr:nth-child(even) td {
      background-color: @c_table_bg_evenrow;
    }

    // &.hoverline tr:hover td {
    //   background-color: @c_table_hover;
    //   color: #fff;
    //
    //   a {
    //     color: #fff !important;
    //
    //     &:hover {
    //       text-decoration: underline;
    //     }
    //   }
    // }
  }

  // .fakefancytable {
  //
  //   .fakerow:nth-child(odd) {
  //     background-color: @c_table_bg_oddrow;
  //   }
  //
  //   .fakerow:nth-child(even) {
  //     background-color: @c_table_bg_evenrow;
  //   }
  // }
}
