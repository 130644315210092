.pagination {
  text-align: center;
  margin: 0;
  // padding: 1em 0;
  padding: 0;

  &::after {
    .rowClearHack();
  }

  .next,
  .prev {
    // width: 4.5em;
    width: auto;
  }

  .prev {
    float: left;
  }

  .next {
    float: right;
  }

  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline-block;

    a {
      border: (2 * @pixel) solid @c_link;
      // padding-bottom: 0.2em;
      display: block;
      min-width: 2.25em;
      line-height: 2.25;
      margin: 0.25em;
      text-align: center;

      svg {
        width: 2.25em;
        height: 2.25em;
        vertical-align: bottom;
        display: block;
      }
      // // border-radius: 0.5em;
      // border-radius: (2 * @pixel);
      // .actButton();
      // border: solid (2 * @pixel) @c_button;
      // background-color: @c_button;
      // color: @c_button_txt;
    }

    &.active a,
    a:active,
    a:hover {
      border-color: @c_button_active_bg;
      background-color: @c_button_active_bg;
      color: @c_button_active_txt;
    }

    &.disabled a {
      // background-color: @c_button_disabled;
      background-color: transparent;
      color: @c_button_disabled_txt;
      border-color: @c_button_disabled;
      cursor: default;
    }
  }
}
