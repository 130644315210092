@import (reference) "const.less";
// @import (reference) "method.less";
@colBaseUnit: 8.5%;

// .contentwrap {
//   max-width: @content-maxw;
//   margin: 0 auto;
//   box-sizing: border-box;
// }

@media screen {
  .padded {
    padding: 2em;
  }

  .hpadded {
    padding-left: 1em;
    padding-right: 1em;
  }

  .vpadded {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .contentwrap {
    max-width: @content-maxw;
    margin: 0 auto;

    &.padded {
      padding: 0 1em;
    }

    &.fpadded {
      padding: 1em;
    }
  }
}

@media screen and (min-width: @screen_gt_small) and (max-width: @screen_lt_medium) {

  .contentwrap {
    max-width: @content-maxw-s2m;

    &.for-topmenu {
      max-width: @content-maxw-s2m - 4em;
    }

    &.padded {
      padding: 0;
    }

    &.fpadded {
      padding: 1em 0;
    }
  }
}

@media screen and (min-width: @screen_gt_medium) and (max-width: @screen_lt_large) {

  .contentwrap {
    max-width: @content-maxw-m2l;

    &.padded {
      padding: 0;
    }

    &.fpadded {
      padding: 1em 0;
    }
  }
}

@media (min-width: @screen_gt_medium) {

  .contentwrap {

    &.padded {
      // padding: 0 3em;
      padding: 0;
    }

    &.fpadded {
      padding: 2em 0;
    }
  }

  .padded {
    padding: 2em 3em;
  }

  .hpadded {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (min-width: @screen_gt_large) {

  .contentwrap {

    &.for-topmenu {
      max-width: @content-maxw - 6em;
    }
  }
}

/* stylelint-disable */

.xlspan1,
.xlspan2,
.xlspan3,
.xlspan4,
.xlspan5,
.xlspan6,
.xlspan7,
.xlspan8,
.xlspan9,
.xlspan10,
.xlspan11,
.xlspan12,
.onethird,
.twothirds,
.smallspan1,
.smallspan2,
.smallspan3,
.smallspan4,
.smallspan5,
.smallspan6,
.smallspan7,
.smallspan8,
.smallspan9,
.smallspan10,
.smallspan11,
.smallspan12,
.mobspan1,
.mobspan2,
.mobspan3,
.mobspan4,
.mobspan5,
.mobspan6,
.mobspan7,
.mobspan8,
.mobspan9,
.mobspan10,
.mobspan11,
.mobspan12,
.span1,
.span2,
.span3,
.span4,
.span5,
.span6,
.span7,
.span8,
.span9,
.span10,
.span11,
.span12 {
  min-height: @pixel;
  float: left;
  width: 100%;
  box-sizing: border-box;
}
/* stylelint-enable */

.singlecol,
.auto2col,
.auto3col {
  margin-bottom: 2em;
}

@media screen and (min-width: @screen_gt_micro) and (max-width: @screen_lt_small) {

  .mobspan1,
  .mobspan2,
  .mobspan3,
  .mobspan4,
  .mobspan5,
  .mobspan6,
  .mobspan7,
  .mobspan8,
  .mobspan9,
  .mobspan10,
  .mobspan11,
  .mobspan12 {
    float: left;
    margin-left: 2%;

    &.pseudo-first,
    &:first-child {
      margin-left: 0;
    }
  }

  .mobspan1 {
    width: ((1 * @colBaseUnit) - 2%);
  }

  .mobspan2 {
    width: ((2 * @colBaseUnit) - 2%);
  }

  .mobspan3 {
    width: ((3 * @colBaseUnit) - 2%);
  }

  .mobspan4 {
    width: ((4 * @colBaseUnit) - 2%);
  }

  .mobspan5 {
    width: ((5 * @colBaseUnit) - 2%);
  }

  .mobspan6 {
    width: ((6 * @colBaseUnit) - 2%);
  }

  .mobspan7 {
    width: ((7 * @colBaseUnit) - 2%);
  }

  .mobspan8 {
    width: ((8 * @colBaseUnit) - 2%);
  }

  .mobspan9 {
    width: ((9 * @colBaseUnit) - 2%);
  }

  .mobspan10 {
    width: ((10 * @colBaseUnit) - 2%);
  }

  .mobspan11 {
    width: ((11 * @colBaseUnit) - 2%);
  }

  .mobspan12 {
    width: ((12 * @colBaseUnit) - 2%);
    margin-left: 0;
  }
}

@media screen and (min-width: @screen_gt_small) and (max-width: @screen_lt_medium) {

  .smallspan1,
  .smallspan2,
  .smallspan3,
  .smallspan4,
  .smallspan5,
  .smallspan6,
  .smallspan7,
  .smallspan8,
  .smallspan9,
  .smallspan10,
  .smallspan11,
  .smallspan12 {
    float: left;
    margin-left: 2%;

    &.pseudo-first,
    &:first-child {
      margin-left: 0;
    }
  }

  .smallspan1 {
    width: ((1 * @colBaseUnit) - 2%);
  }

  .smallspan2 {
    width: ((2 * @colBaseUnit) - 2%);
  }

  .smallspan3 {
    width: ((3 * @colBaseUnit) - 2%);
  }

  .smallspan4 {
    width: ((4 * @colBaseUnit) - 2%);
  }

  .smallspan5 {
    width: ((5 * @colBaseUnit) - 2%);
  }

  .smallspan6 {
    width: ((6 * @colBaseUnit) - 2%);
  }

  .smallspan7 {
    width: ((7 * @colBaseUnit) - 2%);
  }

  .smallspan8 {
    width: ((8 * @colBaseUnit) - 2%);
  }

  .smallspan9 {
    width: ((9 * @colBaseUnit) - 2%);
  }

  .smallspan10 {
    width: ((10 * @colBaseUnit) - 2%);
  }

  .smallspan11 {
    width: ((11 * @colBaseUnit) - 2%);
  }

  .smallspan12 {
    width: ((12 * @colBaseUnit) - 2%);
    margin-left: 0;
  }
}

@media screen and (min-width: @screen_gt_medium) {

  .singlecol,
  .auto2col,
  .auto3col {

    &.bignomargin {
      margin-bottom: 0;
    }
  }

  .onlysmall {
    display: none !important;
  }

  .front-main-article,
  .front-side-panels,
  .span1,
  .span2,
  .span3,
  .span4,
  .span5,
  .span6,
  .span7,
  .span8,
  .span9,
  .span10,
  .span11,
  .span12 {
    float: left;
    margin-left: 2%;

    &.pseudo-first,
    &:first-child {
      margin-left: 0;
    }
  }

  .span1 {
    width: ((1 * @colBaseUnit) - 2%);
  }

  .span2 {
    width: ((2 * @colBaseUnit) - 2%);
  }

  .span3 {
    width: ((3 * @colBaseUnit) - 2%);
  }

  .span4 {
    width: ((4 * @colBaseUnit) - 4%);
  }

  .span5 {
    width: ((5 * @colBaseUnit) - 2%);
  }

  .front-main-article,
  .front-side-panels,
  .span6 {
    width: ((6 * @colBaseUnit) - 2%);
  }

  .span7 {
    width: ((7 * @colBaseUnit) - 2%);
  }

  .span8 {
    width: ((8 * @colBaseUnit) - 2%);
  }

  .span9 {
    width: ((9 * @colBaseUnit) - 2%);
  }

  .span10 {
    width: ((10 * @colBaseUnit) - 2%);
  }

  .span11 {
    width: ((11 * @colBaseUnit) - 2%);
  }

  .span12 {
    width: ((12 * @colBaseUnit) - 2%);
    margin-left: 0;
  }

  .pull-right {
    float: right;
    text-align: right;
  }
}

@media screen and (max-width: @screen_lt_small) {

  .hidesmall {
    display: none !important;
  }
}

@media screen and (min-width: @screen_gt_small) and (max-width: @screen_lt_medium) {

  .pull-right {
    float: right;
    text-align: right;
  }

  .hidemed {
    display: none !important;
  }

  .medspan1,
  .medspan2,
  .medspan3,
  .medspan4,
  .medspan5,
  .medspan6,
  .medspan7,
  .medspan8,
  .medspan9,
  .medspan10,
  .medspan11,
  .medspan12 {
    float: left;
    margin-left: 2%;

    &.pseudo-first,
    &:first-child {
      margin-left: 0;
    }
  }

  .medspan1 {
    width: ((1 * @colBaseUnit) - 2%);
  }

  .medspan2 {
    width: ((2 * @colBaseUnit) - 2%);
  }

  .medspan3 {
    width: ((3 * @colBaseUnit) - 2%);
  }

  .medspan4 {
    width: ((4 * @colBaseUnit) - 2%);
  }

  .medspan5 {
    width: ((5 * @colBaseUnit) - 2%);
  }

  .medspan6 {
    width: ((6 * @colBaseUnit) - 2%);
  }

  .medspan7 {
    width: ((7 * @colBaseUnit) - 2%);
  }

  .medspan8 {
    width: ((8 * @colBaseUnit) - 2%);
  }

  .medspan9 {
    width: ((9 * @colBaseUnit) - 2%);
  }

  .medspan10 {
    width: ((10 * @colBaseUnit) - 2%);
  }

  .medspan11 {
    width: ((11 * @colBaseUnit) - 2%);
  }

  .medspan12 {
    width: ((12 * @colBaseUnit) - 2%);
    margin-left: 0;
  }
}

// ol.columnList,
// ul.columnList {
//   -webkit-column-count: 2;
//   -moz-column-count: 2;
//   column-count: 2;
//   -webkit-column-width: 15em;
//   -moz-column-width: 15em;
//   column-width: 15em;
//   -moz-column-gap: 3em;
//   -webkit-column-gap: 3em;
//   column-gap: 3em;
//   -webkit-column-rule: (2 * @pixel) solid @c_hr_border;
//   -moz-column-rule: (2 * @pixel) solid @c_hr_border;
//   column-rule: (2 * @pixel) solid @c_hr_border;
// }

@media screen and (min-width: @screen_gt_mob) and (max-width: @screen_lt_medium) {
  // .real2col,
  // .real3col {
  //
  // }
}

@media screen and (min-width: @screen_gt_medium) and (max-width: @screen_lt_large) {

  .hidemedlarge {
    display: none !important;
  }

  .auto2col,
  .auto3col {
    float: left;
    margin-left: 2%;
    width: ((6 * @colBaseUnit) - 2%);

    &.pseudo-first,
    &:first-child {
      margin-left: 0;
    }

    &:nth-of-type(2n + 1),
    &.odd {
      margin-left: 0;
      clear: left;
    }
  }

  .centered-lastrow {

    .auto2col,
    .auto3col {

      &.odd {

        &.last {
          margin-left: ((3 * @colBaseUnit));
        }
      }
    }
  }

  .stretch-lastrow {

    .auto2col,
    .auto3col {

      &.odd {

        &.last {
          width: ((12 * @colBaseUnit) - 2%);
        }
      }
    }
  }
}

@media screen and (min-width: @screen_gt_large) {

  .auto2col,
  .auto3col {
    float: left;
    margin-left: 2%;

    &.pseudo-first,
    &:first-child {
      margin-left: 0;
    }
  }

  .auto2col {
    width: ((6 * @colBaseUnit) - 2%);

    &:nth-of-type(2n + 1),
    &.odd {
      margin-left: 0;
      clear: left;
    }
  }

  .auto3col {
    width: ((4 * @colBaseUnit) - 2%);

    &:nth-of-type(3n + 1),
    &.col1 {
      margin-left: 0;
      clear: left;
    }
  }

  .centered-lastrow {

    .auto2col {

      &.odd {

        &.last {
          margin-left: ((3 * @colBaseUnit));
        }
      }
    }

    .auto3col {

      &.col1 {

        &.penultimate {
          margin-left: ((2 * @colBaseUnit));
        }

        &.last {
          margin-left: ((4 * @colBaseUnit));
        }
      }
    }
  }

  .stretch-lastrow {

    .auto2col {

      &.odd {

        &.last {
          width: ((12 * @colBaseUnit) - 2%);
        }
      }
    }

    .auto3col {

      &.col1 {

        &.penultimate {
          width: ((8 * @colBaseUnit) - 2%);
        }

        &.last {
          width: ((12 * @colBaseUnit) - 2%);
        }
      }
    }
  }

  .front-main-article {
    width: ((7 * @colBaseUnit) - 2%);
  }

  .front-side-panels {
    width: ((5 * @colBaseUnit) - 2%);
  }
}

@media screen and (min-width: @screen_gt_xlarge) {

  .xlspan1,
  .xlspan2,
  .xlspan3,
  .xlspan4,
  .xlspan5,
  .xlspan6,
  .xlspan7,
  .xlspan8,
  .xlspan9,
  .xlspan10,
  .xlspan11,
  .xlspan12 {
    float: left;
    margin-left: 2%;

    &.pseudo-first,
    &:first-child {
      margin-left: 0;
    }
  }

  .xlspan1 {
    width: ((1 * @colBaseUnit) - 2%);
  }

  .xlspan2 {
    width: ((2 * @colBaseUnit) - 2%);
  }

  .xlspan3 {
    width: ((3 * @colBaseUnit) - 2%);
  }

  .xlspan4 {
    width: ((4 * @colBaseUnit) - 2%);
  }

  .xlspan5 {
    width: ((5 * @colBaseUnit) - 2%);
  }

  .xlspan6 {
    width: ((6 * @colBaseUnit) - 2%);
  }

  .xlspan7 {
    width: ((7 * @colBaseUnit) - 2%);
  }

  .xlspan8 {
    width: ((8 * @colBaseUnit) - 2%);
  }

  .xlspan9 {
    width: ((9 * @colBaseUnit) - 2%);
  }

  .xlspan10 {
    width: ((10 * @colBaseUnit) - 2%);
  }

  .xlspan11 {
    width: ((11 * @colBaseUnit) - 2%);
  }

  .xlspan12 {
    width: ((12 * @colBaseUnit) - 2%);
    margin-left: 0;
  }
}
