@import (reference) "../common/const.less";

.reference-block {

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    position: relative;

    .theanchor {
      position: absolute;
      top: -6em;
    }

    .theid {
      width: 3em;
      text-align: right;
      font-weight: @wt_bold;
      left: -2.5em;
      position: absolute;
      top: 0;
    }

    .theref {
      margin: 1rem 1.5em;
      // &.withid { }

      p {
        font-size: 1em;
      }

      // a {
      //
      //   font-size: 0.9em;
      // }
    }
  }
}

@media screen and (max-width: @screen_lt_medium) {

  .reference-block li {

    .theid {
      left: -0.8em;
    }

    .theref {

      &.withid {
        margin-left: 3em;
      }
    }
  }
}
