@import (reference) "../common/const.less";

.submenuStyling(@b_color, @b_w) {
  background-color: @b_color;
  padding: 0 0 @b_w 0;

  li {
    margin: 0;
    padding: @b_w @b_w 0 @b_w;
    list-style: none;
  }

  a {
    display: block;
    border-left-width: 0.5em;
    border-left-style: solid;
    border-left-color: @b_color;
    padding: 0.5em;
    background-color: @c_base_white;

    color: @c_menu_txt;
    // &:visited { }

    &:active,
    &:hover {
      border-left-color: @c_link_hover;
      color: @c_link_hover;
    }
  }

  li.active a {
    border-left-color: @c_menu_txt;
  }
}

.top-menu-spacer {
  height: @pixel;
}

#topp {
  display: block;
  height: 4em;
}

.top-menu {
  background-color: @c_menu_bg;
  // border-bottom: @pixel solid @c_menu_border;
  box-shadow: 0 1px 6px 4px rgba(0, 0, 0, 0.06);

  line-height: 1.5;
  padding: 1em;

  .top-logo {
    display: block;

    img {
      display: block;
      height: 2.5em;
      object-fit: contain;
      // width: 3.5em;
    }
  }

  .top-logo.desktop-logo {
    float: left;

    max-width: 25%;

    img {
      width: 8em;
      height: 2em;
    }
  }

  .top-logo.mob-logo {
    max-width: 50%;
    float: left;

    img {
      width: 3.5em;
    }
  }

  .menu {
    display: block;
    margin: 0;
    padding: 0;

    li {
      text-align: left;
      display: block;
      list-style-type: none;
      margin: 0;
      padding: 0;

      span,
      a,
      a:visited {
        // padding: 0.25em 0.5em;
        display: block;
        // color: @c_menu_txt;
        // background-color: @c_menu_bg;
        text-decoration: none;

        // &:hover,
        // &:active {
        //   color: @c_menu_active_txt;
        //   background-color: @c_menu_active_bg;
        // }
      }

      // a { border-bottom: (2 * @pixel) solid @c_menu_bg; }
      // &.active a { border-bottom-color: @c_menu_active_bg; }

      a {
        font-weight: @wt_normal;
      }

      &.ancestor > a,
      &.active > a {
        font-weight: @wt_bold;
      }
    }

    & > li {
      // position: relative;

      .submenu {
        .submenuStyling(@c_menu_border, @pixel);

        // padding: @pixel 0;
        // background-color: @c_menu_border;

        a {
          // margin: @pixel 0 @pixel 1em;
        }
        // position: absolute;
        // top: 3.5em;
        // width: 120%;
        // left: -10%;
        // padding: 0;
      }
    }
  }

  .menu-toggler {
    position: absolute;
    right: 0.5em;
    top: 0.5em;

    svg {
      width: 3em;
      height: 3em;
      display: block;
      cursor: pointer;

      rect {
        fill: currentColor;
        // @c_def_txt;
      }

      .splitBar1,
      .splitBar2 {
        transform-origin: 50% 50%;
        transition: transform 500ms;
      }

      .topBar {
        transform-origin: (11.5em / 16) (15em / 16);
        transition: transform 500ms;
      }

      .fadeBar,
      .midBar {
        transition: fill-opacity 500ms;
      }

      .botBar {
        transform-origin: (11.5em / 16) (33em / 16);
        transition: transform 500ms;
      }
    }
  }
}

.menu-visible .top-menu .menu-toggler {

  svg {

    .splitBar1,
    .topBar {
      transform: rotate(45deg);
    }

    .fadeBar,
    .midBar {
      fill-opacity: 0;
    }

    .splitBar2,
    .botBar {
      transform: rotate(-45deg);
    }
  }
}

@media screen and (max-width: @screen_lt_menu_cutoff) {

  .top-menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;

    .top-logo.desktop-logo {
      // display: none !important;
      max-width: 50%;
    }

    .menu {
      display: none;
      max-width: 35em;
      margin: 0 auto;
      background: transparent;

      span,
      a,
      a:visited {
        // padding: 0.25em 0.5em;
        // display: block;
        color: @c_dbg_txt;
        // background-color: @c_menu_bg;
        // text-decoration: none;

        &:hover,
        &:active {
          color: @c_menu_active_txt;
          background-color: @c_menu_active_bg;
        }
      }

      & > li {

        a {
          font-size: 1.375em;
          line-height: 1.5em;
          margin: 0.875rem 0;
          padding: 0.5rem;
          // background-color: transparent;
        }

        .submenu {
          display: none;
        }
      }
    }

    .toggler-bar {
      height: 2em;
      box-sizing: border-box;
    }

    // .menu-toggler svg { }

    .menu-visible & {
      background-color: @c_mobile_expanded_menu_bg;
      color: @c_dbg_txt;
      min-height: 70vh;

      .top-logo {
        display: none !important;
      }

      .menu {
        margin-top: 1em;
        display: block;
      }
    }
  }

  .menu-visible .main-content {
    // display: none;
  }
}

@media screen and (min-width: @screen_gt_menu_cutoff) {

  .top-menu-spacer {
    display: block;
    height: 4em;

    &.negmargin {
      margin-top: -4em;
      height: 5em;
    }
  }

  .top-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 1em 1em;

    .top-logo.desktop-logo {
      margin-right: 2.5em;
    }

    .toggler-bar {
      display: none !important;
    }

    .menu {
      // text-align: right;
      // margin-left: 4em;

      span,
      a,
      a:visited {
        // padding: 0.25em 0.5em;
        // display: block;
        color: @c_menu_txt;
        background-color: @c_menu_bg;
        // text-decoration: none;

        &:hover,
        &:active {
          color: @c_menu_active_txt;
          background-color: @c_menu_active_bg;
        }
      }

      & > li {
        position: relative;
        z-index: 1;

        & > a {
          padding: 0.25em 2em 0.25em 0;
          // text-align: center;
        }

        &.has_submenu > a {
          width: 8em;
          padding-left: 1em;
          padding-right: 1em;
        }

        .submenu {
          position: absolute;
          z-index: 3;
          top: 2.5em;
          left: 0;
          width: 10em;
          // width: 140%;
          // left: -20%;
          box-shadow: 0.1em 0.4em 0.6em rgba(0, 0, 0, 0.1);
          // padding: 0;
          margin: 0;
          // toggling
          opacity: 0;
          transition: opacity 600ms;
          transition-delay: 300ms;

          &:empty {
            display: none;
          }

          li,
          a {
            display: block;
            display: block;
            max-width: 100%;
            width: auto;
            // text-align: center;
          }

          // a {
          //   // margin: @pixel;
          // }
        }

        &:hover {
          z-index: 2;

          .submenu {
            z-index: 4;
            opacity: 1;
            transition-delay: 1ms;
            transition-duration: 200ms;
          }
        }
      }

      li {
        display: inline-block;
      }

      .home-link {
        display: none !important;
      }
    }
  }
}

@inpage_submenu_border: @pixel;
@inpage_submenu_border_color: @c_canvas_bg;

.in-page-submenu {
  clear: both;

  ul {
    .submenuStyling(@inpage_submenu_border_color, @pixel);
    margin: 1em 0;

    h4 {
      margin: 0;
    }
    // margin: 1em 0;
    // padding: 0 0 @inpage_submenu_border 0;
    //
    // li {
    //   margin: 0;
    //   padding: @inpage_submenu_border @inpage_submenu_border 0
    //     @inpage_submenu_border;
    //   list-style: none;
    // }
    //
    // h4 {
    //   margin: 0;
    // }
    //
    // a {
    //   .submenuStyling(@inpage_submenu_border_color);
    //
    // }
  }
}
