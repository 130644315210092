// .slide-toggleable {
//   max-height: 0em;
//   overflow: hidden;
//   transition: max-height 0.5s;
//
//   .expanded & {
//     max-height: 200vh;
//   }
// }

.article-faq-item {
  padding: 0 0 1.125em;
  margin-bottom: 1.125em;
  border-bottom: 1px solid @c_base_extra-light-gray;

  h4.question {
    font-weight: @wt_normal;
    letter-spacing: @letter_spacing_2;
    line-height: 1.5;
    // 1.125
  }

  .submitted-date,
  .answered-date {
    color: @c_base_warm-gray;
    margin: 0.375em 0;
    display: block;
  }

  .answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }

  .bottom-toggler {
    margin-top: -1.7em;
    transition: margin-top 0.5s;
  }

  .show-less,
  .show-more {
    background-color: @c_base_white;

    color: @c_link;
    font-weight: @wt_link;
    letter-spacing: @letter_spacing_4;
    cursor: pointer;

    &:active,
    &:hover {
      color: @c_link_hover;
    }
  }

  .show-less {
    display: none;
  }

  &.expanded {

    .bottom-toggler {
      margin-top: 0;
    }

    .answer {
      max-height: none;
    }

    .show-less {
      display: inline;
    }

    .show-more {
      display: none;
    }
  }
}

.question-form {
  margin: 2em 0;

  .pseudorow {
    // .the-helptext,

    .the-description {
      margin-bottom: 1em;
    }
  }

  .rich-text {

    p {
      margin: 0 0 0.5em;
    }

    i {
      font-style: normal;
      color: @c_base_warm-gray;
      font-size: 0.875em;
    }
  }
}
@media screen and (min-width: @screen_gt_medium) {

  .question-form {

    .pseudorow {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;

      &.centered {
        align-items: center;
      }

      .the-link,
      .the-helptext,
      .the-description,
      .the-form {
        flex: 0 1 46%;
      }

      .the-form {
        order: 1;
      }

      .the-helptext,
      .the-description {
        order: 2;
      }

      .the-helptext {
        vertical-align: middle;
      }

      .the-link {
        order: 3;
      }
    }
  }
}
