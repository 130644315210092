.frontpage-widget {

  .widget-slogan {
    margin-top: 2.5em;
  }

  .features {
    padding: 1em 1.5em 0.5em;
    box-shadow: @white_on_white_glow;
    margin: 2.5em 0;
    background-color: @c_base_white;
  }

  .feature {
    padding: 1.5em 0 0.5em;
    border-bottom: 1px solid @c_base_extra-light-gray;

    &:last-child {
      border-bottom: 0;
    }

    h6 {
      margin-top: 0;
    }

    .the-text,
    h6 {
      position: relative;
      padding-right: 2.5em;
    }

    .check-icon {
      width: 1.5em;
      height: 1.5em;
      position: absolute;
      top: 0;
      right: 0;
      color: @c_base_metallic_blue;
    }

    .read-more-link {
      display: block;
      margin: 1em 0 0.75em;
    }
  }
}
@media screen and (min-width: @screen_gt_large) {

  .frontpage-widget {

    .widget-slogan {
      margin-right: 3em;
    }

    .feature {

      .the-text,
      h6 {
        padding-right: 4.375em;
      }

      .check-icon {
        right: 1.875em;
      }
    }
  }
}
