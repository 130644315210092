// .contact-field {
//   display: block;
//   margin: 0.2em 0;
//
//   a {
//     display: block;
//     padding: 0.25em 0;
//   }
// }

.social-media-wrap {
  margin-top: 1.5em;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;

  &:empty {
    display: none;
  }
}

.social-media-link {
  width: 2em;
  height: 2em;
  display: inline-block;
  margin: 0.25em;
  flex: 0 0 2em;
}

@media screen and (min-width: @screen_gt_medium) {

  .social-media-wrap {
    justify-content: space-between;
  }
}

.data-receipt {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1px 1em;
  // border-radius: 1em;
  margin: 1em -1em;
  box-shadow: 0.2em 0.5em 2em rgba(0, 0, 0, 0.1);

  .imagePreview,
  span.colorPreview {
    vertical-align: middle;
    display: inline-block;
    width: 4em;
    height: 4em;
    border: 1px solid #d0dadb;
  }

  .normaltext {
    vertical-align: top;
    display: inline-block;
    white-space: pre-line;
  }

  span.colorPreview {
    border-radius: 0.25em;
  }

  i.empty {
    opacity: 0.5;
  }

  .imagePreview {
    object-fit: contain;
    object-position: left center;
    min-height: 2em;
    max-height: 8em;
    height: auto;
    width: 19em;
    max-width: 100%;
    padding: 3px;
    background-color: #fff;
  }

  .thank-you-field-label {
    display: inline-block;
    width: 10em;
    font-size: 0.9em;

    font-family: @font_def;
    font-weight: @wt_bold;
  }

  .thank-you-field-line {
    margin: 1em auto;
  }
}
