.widebanner {
  position: relative;

  .banner-bg-svg,
  .banner-bg {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .banner-content {
    position: relative;
    z-index: 3;
  }

  // .banner-bg-svg { }

  .banner-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .notsmallbanner,
  .smallbanner,
  .medbanner,
  .bigbanner {
    display: none;
  }

  @media screen and (max-width: @screen_lt_medium) {

    .smallbanner {
      display: block;
    }
  }

  @media screen and (min-width: @screen_gt_medium) and (max-width: @screen_lt_xlarge) {

    .notsmallbanner,
    .medbanner {
      display: block;
    }
  }

  @media screen and (min-width: @screen_gt_xlarge) {

    .notsmallbanner,
    .bigbanner {
      display: block;
    }
  }
}

.fancy-video {
  cursor: pointer;
  // position: relative;

  .playbutton {
    width: 7em;
    height: 7em;
    color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -3.5em;
    margin-left: -3.5em;
    z-index: 1;
  }

  .small-playbutton {
    width: 4em;
    height: 4em;
    color: rgba(0, 0, 0, 0.9);
  }

  &:hover {

    .playbutton {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  text-shadow: 0 0 1em rgba(255, 255, 255, 0.6);

  &.dbg {
    color: #fff;
    background-color: #000;
    text-shadow: 0 0 1em rgba(0, 0, 0, 0.3);

    .playbutton {
      color: rgba(255, 255, 255, 0.6);
    }

    .small-playbutton {
      color: rgba(255, 255, 255, 1);
    }

    &:hover {

      .playbutton {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .overlay-text {
    box-sizing: border-box;
  }

  .banner-bg-svg {
    pointer-events: none;
  }

  .overlay-video {
    visibility: hidden;
    transition-property: opacity;
    transition-duration: 1s;
    opacity: 0;
    position: relative;
    z-index: 0;
    width: 150vh;
    max-width: 100%;
    margin: 0 auto;
  }

  .banner-bg,
  .banner-bg-svg {
    transition-property: opacity;
    transition-duration: 1s;
  }

  &.show-video {
    // background-color: #000;

    .overlay-video {
      opacity: 1;
      visibility: visible;
      z-index: 6;
    }

    .overlay-text {
      display: none;
    }

    .banner-bg,
    .banner-bg-svg {
      opacity: 0;
    }
  }
}

.image-cta,
.frontpage-cta {
  background-color: @c_base_light_gray;
  overflow: hidden;

  .banner-content {
    display: flex;
    margin: 7em 0 1em;
    // margin: 10.75em 0 1.5em;

    .banner-text-content .inner-pad {
      padding: 1em 1.5em 1em 2em;
      max-width: 22em;
      float: left;
      width: auto;
    }

    .banner-pre-margin,
    .banner-text-content .inner-pad {
      color: @c_base_metallic_blue;
      background-color: rgba(255, 255, 255, 0.9);
    }

    &.dbg {

      .banner-pre-margin,
      .banner-text-content .inner-pad {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }

    .banner-pre-margin,
    .banner-post-margin {
      // background-color: blue;
    }

    .banner-pre-margin,
    .banner-post-margin,
    .banner-after-text,
    .banner-text-content {
      flex: 1 1 auto;
    }

    .banner-text-content {
      flex-basis: 100%;
      flex-grow: 0;
    }

    .banner-post-margin {
      flex-basis: 1em;
    }

    // .banner-after-text {
    //   flex-basis: 2%;
    //   flex-grow: 0;
    // }
  }
}

// .hpadded {
//   background-color: pink;
// }
// hpadded = 2em
@media screen and (min-width: @screen_gt_small) and (max-width: @screen_lt_medium) {
  // hpadded = 2em

  .image-cta,
  .frontpage-cta {

    .banner-content {
      margin-top: 9em;

      .banner-text-content .inner-pad {
        // padding: 1em 1.5em 1em 2em;
      }

      .banner-text-content {
        flex-basis: @content-maxw-s2m;
      }
      // .banner-text-content {
      //   flex-basis: @content-maxw-s2m*0.80;
      // }
      //
      // .banner-after-text {
      //   flex-basis: @content-maxw-s2m*0.20;
      // }
    }
  }
}

@media screen and (min-width: @screen_gt_medium) and (max-width: @screen_lt_large) {
  // hpadded = 3em

  .image-cta,
  .frontpage-cta {

    .banner-content {

      .banner-text-content .inner-pad {
        // padding: 1em 1.5em 1em 2em;
      }

      .banner-text-content {
        flex-basis: @content-maxw-m2l;
      }
      // .banner-text-content {
      //   flex-basis: @content-maxw-m2l*0.55;
      // }
      //
      // .banner-after-text {
      //   flex-basis: @content-maxw-m2l*0.45;
      // }
    }
  }
}

@media (min-width: @screen_gt_medium) {

  .image-cta,
  .frontpage-cta {

    .banner-content {
      margin-bottom: 1.5em;

      margin-top: 10.75em;

      .banner-text-content .inner-pad {
        padding-left: 3em;
        max-width: 28em;
      }
    }
  }
}
@media (min-width: @screen_gt_large) {

  .image-cta,
  .frontpage-cta {

    .banner-content {
      // margin-top: 10.75em;

      .banner-text-content {
        flex-basis: @content-maxw;
      }
      // .banner-text-content {
      //   flex-basis: @content-maxw*0.55;
      // }
      //
      // .banner-after-text {
      //   flex-basis: @content-maxw*0.45;
      // }
    }
  }
}
@media (min-width: @screen_gt_xlarge) {

  .image-cta,
  .frontpage-cta {

    .banner-content {
      margin-top: 13.75vw;
    }
  }
}

// @media screen and (max-width: @screen_lt_small) {
//
//   .notify-popups-area {
//     // margin-top: 10em;
//
//     .notification {
//       margin-left: 0;
//     }
//   }
// }
//
// @media screen and (max-width: @screen_lt_small) {
//
//   .fancy-video .overlay-text {
//     min-height: 56.25vw;
//   }
// }
//
// @media screen and (min-width: @screen_gt_small) and (max-width: @screen_lt_medium) {
//
//   .fancy-video .overlay-text {
//     min-height: 20.8125em;
//   }
// }
//
// @media screen and (min-width: @screen_gt_small) {
//
//   .fancy-video {
//
//     &.show-video {
//       background-color: #000;
//     }
//   }
// }
//
// @media screen and (max-width: @screen_lt_medium) {
//
//   .frontpage-cta .banner-text-content {
//     max-width: 20em;
//   }
//
//   .fancy-video {
//
//     .playbutton {
//       display: none;
//     }
//
//     .banner-bg,
//     .banner-bg-svg {
//       opacity: 0.6;
//     }
//
//     .overlay-text {
//       text-align: center;
//     }
//
//     .overlay-video {
//       display: none;
//     }
//
//     &.show-video {
//
//       .overlay-video {
//         display: block;
//       }
//
//       .overlay-text {
//         display: none;
//       }
//     }
//   }
// }
//
// @media screen and (min-width: @screen_gt_medium) {
//
//   .frontpage-cta .banner-text-content {
//     padding-right: 0;
//   }
//
//   .fancy-video {
//
//     .small-playbutton {
//       display: none;
//     }
//
//     .overlay-text {
//       position: absolute;
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       z-index: 2;
//     }
//   }
// }
//
// @media screen and (min-width: @screen_gt_small) and (max-width: @screen_lt_medium) {
//
//   .notify-popups-area {
//
//     .notification {
//       margin-left: 0;
//     }
//   }
// }
//
// @media screen and (min-width: @screen_gt_medium) and (max-width: @screen_lt_large) {
//
//   .notify-popups-area {
//     margin-top: 10em;
//
//     .notification {
//       margin-right: 0;
//     }
//   }
// }
//
// @media screen and (min-width: @screen_gt_large) {
//
//   .notify-popups-area {
//     margin-top: 10em;
//
//     .notification {
//       margin-right: 0;
//     }
//   }
// }
