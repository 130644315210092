body,
html {
  margin: 0;
  padding: 0;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
.clearfix {
  clear: both;
}
.row {
  padding: 0.0625rem 0;
}
.row.nested .row,
.row.unpadded {
  padding: 0;
}
.screen-reader-text {
  position: absolute;
  left: -10000em;
  top: -10000em;
}
.unpadded {
  padding: 0 !important;
}
.row::after {
  content: "_";
  clear: both;
  height: 0;
  display: block;
  overflow: hidden;
}
@media screen and (max-width: 37.49375em) {
  .mobrow::after {
    content: "_";
    clear: both;
    height: 0;
    display: block;
    overflow: hidden;
  }
}
@media screen and (min-width: 37.5em) {
  .bigrow::after {
    content: "_";
    clear: both;
    height: 0;
    display: block;
    overflow: hidden;
  }
}
@media screen {
  .padded {
    padding: 2em;
  }
  .hpadded {
    padding-left: 1em;
    padding-right: 1em;
  }
  .vpadded {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .contentwrap {
    max-width: 60em;
    margin: 0 auto;
  }
  .contentwrap.padded {
    padding: 0 1em;
  }
  .contentwrap.fpadded {
    padding: 1em;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 49.99375em) {
  .contentwrap {
    max-width: 37em;
  }
  .contentwrap.for-topmenu {
    max-width: 33em;
  }
  .contentwrap.padded {
    padding: 0;
  }
  .contentwrap.fpadded {
    padding: 1em 0;
  }
}
@media screen and (min-width: 50em) and (max-width: 65.61875em) {
  .contentwrap {
    max-width: 49em;
  }
  .contentwrap.padded {
    padding: 0;
  }
  .contentwrap.fpadded {
    padding: 1em 0;
  }
}
@media (min-width: 50em) {
  .contentwrap.padded {
    padding: 0;
  }
  .contentwrap.fpadded {
    padding: 2em 0;
  }
  .padded {
    padding: 2em 3em;
  }
  .hpadded {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (min-width: 65.625em) {
  .contentwrap.for-topmenu {
    max-width: 54em;
  }
}
/* stylelint-disable */
.xlspan1,
.xlspan2,
.xlspan3,
.xlspan4,
.xlspan5,
.xlspan6,
.xlspan7,
.xlspan8,
.xlspan9,
.xlspan10,
.xlspan11,
.xlspan12,
.onethird,
.twothirds,
.smallspan1,
.smallspan2,
.smallspan3,
.smallspan4,
.smallspan5,
.smallspan6,
.smallspan7,
.smallspan8,
.smallspan9,
.smallspan10,
.smallspan11,
.smallspan12,
.mobspan1,
.mobspan2,
.mobspan3,
.mobspan4,
.mobspan5,
.mobspan6,
.mobspan7,
.mobspan8,
.mobspan9,
.mobspan10,
.mobspan11,
.mobspan12,
.span1,
.span2,
.span3,
.span4,
.span5,
.span6,
.span7,
.span8,
.span9,
.span10,
.span11,
.span12 {
  min-height: 0.0625rem;
  float: left;
  width: 100%;
  box-sizing: border-box;
}
/* stylelint-enable */
.singlecol,
.auto2col,
.auto3col {
  margin-bottom: 2em;
}
@media screen and (min-width: 20.625em) and (max-width: 37.49375em) {
  .mobspan1,
  .mobspan2,
  .mobspan3,
  .mobspan4,
  .mobspan5,
  .mobspan6,
  .mobspan7,
  .mobspan8,
  .mobspan9,
  .mobspan10,
  .mobspan11,
  .mobspan12 {
    float: left;
    margin-left: 2%;
  }
  .mobspan1.pseudo-first,
  .mobspan2.pseudo-first,
  .mobspan3.pseudo-first,
  .mobspan4.pseudo-first,
  .mobspan5.pseudo-first,
  .mobspan6.pseudo-first,
  .mobspan7.pseudo-first,
  .mobspan8.pseudo-first,
  .mobspan9.pseudo-first,
  .mobspan10.pseudo-first,
  .mobspan11.pseudo-first,
  .mobspan12.pseudo-first,
  .mobspan1:first-child,
  .mobspan2:first-child,
  .mobspan3:first-child,
  .mobspan4:first-child,
  .mobspan5:first-child,
  .mobspan6:first-child,
  .mobspan7:first-child,
  .mobspan8:first-child,
  .mobspan9:first-child,
  .mobspan10:first-child,
  .mobspan11:first-child,
  .mobspan12:first-child {
    margin-left: 0;
  }
  .mobspan1 {
    width: 6.5%;
  }
  .mobspan2 {
    width: 15%;
  }
  .mobspan3 {
    width: 23.5%;
  }
  .mobspan4 {
    width: 32%;
  }
  .mobspan5 {
    width: 40.5%;
  }
  .mobspan6 {
    width: 49%;
  }
  .mobspan7 {
    width: 57.5%;
  }
  .mobspan8 {
    width: 66%;
  }
  .mobspan9 {
    width: 74.5%;
  }
  .mobspan10 {
    width: 83%;
  }
  .mobspan11 {
    width: 91.5%;
  }
  .mobspan12 {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 49.99375em) {
  .smallspan1,
  .smallspan2,
  .smallspan3,
  .smallspan4,
  .smallspan5,
  .smallspan6,
  .smallspan7,
  .smallspan8,
  .smallspan9,
  .smallspan10,
  .smallspan11,
  .smallspan12 {
    float: left;
    margin-left: 2%;
  }
  .smallspan1.pseudo-first,
  .smallspan2.pseudo-first,
  .smallspan3.pseudo-first,
  .smallspan4.pseudo-first,
  .smallspan5.pseudo-first,
  .smallspan6.pseudo-first,
  .smallspan7.pseudo-first,
  .smallspan8.pseudo-first,
  .smallspan9.pseudo-first,
  .smallspan10.pseudo-first,
  .smallspan11.pseudo-first,
  .smallspan12.pseudo-first,
  .smallspan1:first-child,
  .smallspan2:first-child,
  .smallspan3:first-child,
  .smallspan4:first-child,
  .smallspan5:first-child,
  .smallspan6:first-child,
  .smallspan7:first-child,
  .smallspan8:first-child,
  .smallspan9:first-child,
  .smallspan10:first-child,
  .smallspan11:first-child,
  .smallspan12:first-child {
    margin-left: 0;
  }
  .smallspan1 {
    width: 6.5%;
  }
  .smallspan2 {
    width: 15%;
  }
  .smallspan3 {
    width: 23.5%;
  }
  .smallspan4 {
    width: 32%;
  }
  .smallspan5 {
    width: 40.5%;
  }
  .smallspan6 {
    width: 49%;
  }
  .smallspan7 {
    width: 57.5%;
  }
  .smallspan8 {
    width: 66%;
  }
  .smallspan9 {
    width: 74.5%;
  }
  .smallspan10 {
    width: 83%;
  }
  .smallspan11 {
    width: 91.5%;
  }
  .smallspan12 {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (min-width: 50em) {
  .singlecol.bignomargin,
  .auto2col.bignomargin,
  .auto3col.bignomargin {
    margin-bottom: 0;
  }
  .onlysmall {
    display: none !important;
  }
  .front-main-article,
  .front-side-panels,
  .span1,
  .span2,
  .span3,
  .span4,
  .span5,
  .span6,
  .span7,
  .span8,
  .span9,
  .span10,
  .span11,
  .span12 {
    float: left;
    margin-left: 2%;
  }
  .front-main-article.pseudo-first,
  .front-side-panels.pseudo-first,
  .span1.pseudo-first,
  .span2.pseudo-first,
  .span3.pseudo-first,
  .span4.pseudo-first,
  .span5.pseudo-first,
  .span6.pseudo-first,
  .span7.pseudo-first,
  .span8.pseudo-first,
  .span9.pseudo-first,
  .span10.pseudo-first,
  .span11.pseudo-first,
  .span12.pseudo-first,
  .front-main-article:first-child,
  .front-side-panels:first-child,
  .span1:first-child,
  .span2:first-child,
  .span3:first-child,
  .span4:first-child,
  .span5:first-child,
  .span6:first-child,
  .span7:first-child,
  .span8:first-child,
  .span9:first-child,
  .span10:first-child,
  .span11:first-child,
  .span12:first-child {
    margin-left: 0;
  }
  .span1 {
    width: 6.5%;
  }
  .span2 {
    width: 15%;
  }
  .span3 {
    width: 23.5%;
  }
  .span4 {
    width: 30%;
  }
  .span5 {
    width: 40.5%;
  }
  .front-main-article,
  .front-side-panels,
  .span6 {
    width: 49%;
  }
  .span7 {
    width: 57.5%;
  }
  .span8 {
    width: 66%;
  }
  .span9 {
    width: 74.5%;
  }
  .span10 {
    width: 83%;
  }
  .span11 {
    width: 91.5%;
  }
  .span12 {
    width: 100%;
    margin-left: 0;
  }
  .pull-right {
    float: right;
    text-align: right;
  }
}
@media screen and (max-width: 37.49375em) {
  .hidesmall {
    display: none !important;
  }
}
@media screen and (min-width: 37.5em) and (max-width: 49.99375em) {
  .pull-right {
    float: right;
    text-align: right;
  }
  .hidemed {
    display: none !important;
  }
  .medspan1,
  .medspan2,
  .medspan3,
  .medspan4,
  .medspan5,
  .medspan6,
  .medspan7,
  .medspan8,
  .medspan9,
  .medspan10,
  .medspan11,
  .medspan12 {
    float: left;
    margin-left: 2%;
  }
  .medspan1.pseudo-first,
  .medspan2.pseudo-first,
  .medspan3.pseudo-first,
  .medspan4.pseudo-first,
  .medspan5.pseudo-first,
  .medspan6.pseudo-first,
  .medspan7.pseudo-first,
  .medspan8.pseudo-first,
  .medspan9.pseudo-first,
  .medspan10.pseudo-first,
  .medspan11.pseudo-first,
  .medspan12.pseudo-first,
  .medspan1:first-child,
  .medspan2:first-child,
  .medspan3:first-child,
  .medspan4:first-child,
  .medspan5:first-child,
  .medspan6:first-child,
  .medspan7:first-child,
  .medspan8:first-child,
  .medspan9:first-child,
  .medspan10:first-child,
  .medspan11:first-child,
  .medspan12:first-child {
    margin-left: 0;
  }
  .medspan1 {
    width: 6.5%;
  }
  .medspan2 {
    width: 15%;
  }
  .medspan3 {
    width: 23.5%;
  }
  .medspan4 {
    width: 32%;
  }
  .medspan5 {
    width: 40.5%;
  }
  .medspan6 {
    width: 49%;
  }
  .medspan7 {
    width: 57.5%;
  }
  .medspan8 {
    width: 66%;
  }
  .medspan9 {
    width: 74.5%;
  }
  .medspan10 {
    width: 83%;
  }
  .medspan11 {
    width: 91.5%;
  }
  .medspan12 {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (min-width: 30em) and (max-width: 49.99375em) {
}
@media screen and (min-width: 50em) and (max-width: 65.61875em) {
  .hidemedlarge {
    display: none !important;
  }
  .auto2col,
  .auto3col {
    float: left;
    margin-left: 2%;
    width: 49%;
  }
  .auto2col.pseudo-first,
  .auto3col.pseudo-first,
  .auto2col:first-child,
  .auto3col:first-child {
    margin-left: 0;
  }
  .auto2col:nth-of-type(2n + 1),
  .auto3col:nth-of-type(2n + 1),
  .auto2col.odd,
  .auto3col.odd {
    margin-left: 0;
    clear: left;
  }
  .centered-lastrow .auto2col.odd.last,
  .centered-lastrow .auto3col.odd.last {
    margin-left: 25.5%;
  }
  .stretch-lastrow .auto2col.odd.last,
  .stretch-lastrow .auto3col.odd.last {
    width: 100%;
  }
}
@media screen and (min-width: 65.625em) {
  .auto2col,
  .auto3col {
    float: left;
    margin-left: 2%;
  }
  .auto2col.pseudo-first,
  .auto3col.pseudo-first,
  .auto2col:first-child,
  .auto3col:first-child {
    margin-left: 0;
  }
  .auto2col {
    width: 49%;
  }
  .auto2col:nth-of-type(2n + 1),
  .auto2col.odd {
    margin-left: 0;
    clear: left;
  }
  .auto3col {
    width: 32%;
  }
  .auto3col:nth-of-type(3n + 1),
  .auto3col.col1 {
    margin-left: 0;
    clear: left;
  }
  .centered-lastrow .auto2col.odd.last {
    margin-left: 25.5%;
  }
  .centered-lastrow .auto3col.col1.penultimate {
    margin-left: 17%;
  }
  .centered-lastrow .auto3col.col1.last {
    margin-left: 34%;
  }
  .stretch-lastrow .auto2col.odd.last {
    width: 100%;
  }
  .stretch-lastrow .auto3col.col1.penultimate {
    width: 66%;
  }
  .stretch-lastrow .auto3col.col1.last {
    width: 100%;
  }
  .front-main-article {
    width: 57.5%;
  }
  .front-side-panels {
    width: 40.5%;
  }
}
@media screen and (min-width: 78.125em) {
  .xlspan1,
  .xlspan2,
  .xlspan3,
  .xlspan4,
  .xlspan5,
  .xlspan6,
  .xlspan7,
  .xlspan8,
  .xlspan9,
  .xlspan10,
  .xlspan11,
  .xlspan12 {
    float: left;
    margin-left: 2%;
  }
  .xlspan1.pseudo-first,
  .xlspan2.pseudo-first,
  .xlspan3.pseudo-first,
  .xlspan4.pseudo-first,
  .xlspan5.pseudo-first,
  .xlspan6.pseudo-first,
  .xlspan7.pseudo-first,
  .xlspan8.pseudo-first,
  .xlspan9.pseudo-first,
  .xlspan10.pseudo-first,
  .xlspan11.pseudo-first,
  .xlspan12.pseudo-first,
  .xlspan1:first-child,
  .xlspan2:first-child,
  .xlspan3:first-child,
  .xlspan4:first-child,
  .xlspan5:first-child,
  .xlspan6:first-child,
  .xlspan7:first-child,
  .xlspan8:first-child,
  .xlspan9:first-child,
  .xlspan10:first-child,
  .xlspan11:first-child,
  .xlspan12:first-child {
    margin-left: 0;
  }
  .xlspan1 {
    width: 6.5%;
  }
  .xlspan2 {
    width: 15%;
  }
  .xlspan3 {
    width: 23.5%;
  }
  .xlspan4 {
    width: 32%;
  }
  .xlspan5 {
    width: 40.5%;
  }
  .xlspan6 {
    width: 49%;
  }
  .xlspan7 {
    width: 57.5%;
  }
  .xlspan8 {
    width: 66%;
  }
  .xlspan9 {
    width: 74.5%;
  }
  .xlspan10 {
    width: 83%;
  }
  .xlspan11 {
    width: 91.5%;
  }
  .xlspan12 {
    width: 100%;
    margin-left: 0;
  }
}
html,
body {
  font-size: 100%;
  letter-spacing: 0.01875rem;
  background-color: #fff;
  color: #444;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
}
.footer-col p:first-child,
.rich-text p:first-child,
.footer-col h1:first-child,
.rich-text h1:first-child,
.footer-col h2:first-child,
.rich-text h2:first-child,
.footer-col h3:first-child,
.rich-text h3:first-child,
.footer-col h4:first-child,
.rich-text h4:first-child,
.footer-col h5:first-child,
.rich-text h5:first-child,
.footer-col h6:first-child,
.rich-text h6:first-child {
  margin-top: 0;
}
.svg-text-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: bottom;
}
.svg-text-icon.small {
  width: 1em;
}
h1,
h2,
h3,
h4,
h5 {
  line-height: 1.29;
}
h1 .svg-text-icon,
h2 .svg-text-icon,
h3 .svg-text-icon,
h4 .svg-text-icon,
h5 .svg-text-icon {
  height: 1.29em;
  width: 1.29em;
}
.text-muted {
  opacity: 0.8;
}
h1 {
  font-size: 2.625em;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin: 2.5rem 0 1rem;
  letter-spacing: 0.01875rem;
}
.block-lead,
.ingress,
h2 {
  font-size: 2em;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin: 1em 0 1rem;
  letter-spacing: 0.01875rem;
}
.block-lead,
.ingress {
  font-size: 1.5em;
}
h3 {
  font-size: 1.75em;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin: 1em 0 1rem;
  letter-spacing: 0;
}
h4 {
  font-size: 1.5em;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin: 1em 0 1rem;
  letter-spacing: 0.01875rem;
}
h5 {
  font-size: 112.5%;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin: 2em 0 0.5rem;
}
h5 + p {
  margin-top: 0;
}
h6 {
  font-size: 1em;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin: 2em 0 0.5rem;
}
h6 + p {
  margin-top: 0;
}
p {
  margin: 1.5em 0;
}
.larger-richtext {
  font-size: 1.25em;
  font-style: normal;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin: 1em 0;
}
b,
strong {
  font-weight: 700;
  letter-spacing: 0.025rem;
}
i,
em {
  font-style: italic;
}
small {
  font-size: 0.875em;
}
.first-published-at,
.byline {
  margin-top: 0.5em;
  margin-bottom: 1em;
  display: inline-block;
}
.page-first-published-at {
  padding: 1em 0.5em 0;
  display: inline-block;
}
.aligntop {
  margin-top: 0 !important;
}
.alignbot {
  margin-bottom: 0 !important;
}
.shareBtnLink {
  display: inline-block;
  padding: 0.1em 0.2em;
  margin: 0.5em 1em;
  cursor: pointer;
}
a {
  color: #4e7488;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.025rem;
}
a img {
  border: 0 none;
  outline: 0;
}
a:visited {
  color: #4e7488;
}
a:active,
a:hover {
  text-decoration: none;
  color: #0081d4;
}
.post-content a {
  color: #0081d4;
}
.post-content a:visited {
  color: #00436e;
}
.post-content a:hover {
  color: #0081d4;
  text-decoration: underline;
}
.post-content .nounderline a {
  text-decoration: none;
}
.dbg a {
  color: #fff;
}
.dbg a:visited {
  color: #cccccc;
}
.dbg a:hover {
  color: #ffc107;
}
a.taglink {
  background-color: #4e7488;
  color: #fff;
  border-radius: 0.25rem;
  font-weight: 700;
  display: inline-block;
  padding: 0 0.7em;
  line-height: 2;
  min-width: 4em;
  text-align: center;
  margin: 0.5em 0.1em;
}
a.taglink:active,
a.taglink:hover {
  background-color: #0081d4;
  color: #fff;
}
@media screen and (max-width: 29.99375em) {
  body {
    font-size: 100%;
  }
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
@media screen and (max-width: 37.49375em) {
  .tiny-left {
    text-align: left;
  }
  .tiny-center {
    text-align: center;
  }
  .tiny-right {
    text-align: right;
  }
}
@media screen and (max-width: 49.99375em) {
  .small-left {
    text-align: left;
  }
  .small-center {
    text-align: center;
  }
  .small-right {
    text-align: right;
  }
  h1 {
    margin-top: 1.5rem;
    font-size: 2em;
    letter-spacing: 0.0125rem;
  }
  .block-lead,
  .ingress,
  h2 {
    font-size: 1.375em;
    letter-spacing: 0.0125rem;
  }
  .block-lead,
  .ingress {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1.25em;
  }
  h4 {
    font-size: 1.125em;
  }
  h5 {
    font-size: 1em;
  }
  .larger-richtext {
    font-size: 1.1em;
  }
}
@media screen and (min-width: 50em) {
  .first-published-at {
    margin-right: 2em;
  }
  .big-left {
    text-align: left;
  }
  .big-center {
    text-align: center;
  }
  .big-right {
    text-align: right;
  }
}
@media screen and (min-width: 100em) {
  body {
    font-size: 112.5%;
  }
}
html,
body {
  margin: 0;
  padding: 0;
}
.main-content {
  min-height: 70vh;
}
.imgrestrictor {
  margin: 0 auto;
  max-width: 100%;
}
.svg-imgrestrictor {
  margin: 0 auto;
  max-width: 100%;
}
.svg-imgrestrictor img {
  width: 100%;
}
.svg-imgrestrictor object {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
img {
  max-width: 100%;
  height: auto;
}
.circular img {
  border-radius: 50%;
}
.mainimg img {
  display: block;
  margin: 0 auto;
}
.mainimg img.tall-mode {
  max-height: 80vh;
  max-width: 100%;
  width: auto;
  height: auto;
}
.mainimg img.portrait-mode {
  max-height: 70vh;
  max-width: 100%;
  width: auto;
  height: auto;
}
.responsive-object {
  position: relative;
}
.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen {
  .all_content_wrapper {
    min-height: 80vh;
    box-sizing: border-box;
    padding-bottom: 3em;
  }
}
.bottom-footer {
  background-color: #f4f4f4;
  color: #444;
  padding: 0.0625rem 0;
  box-sizing: border-box;
  min-height: 20vh;
}
.bottom-footer .padded {
  padding-top: 0.5em;
  padding-bottom: 2em;
}
.bottom-footer h4 {
  opacity: 0.4;
  margin-bottom: 0.5em;
}
.bottom-footer .footer-col {
  padding: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}
.bottom-footer .footer-col ul,
.bottom-footer .footer-col li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.bottom-footer .footer-col ul {
  margin: 0 auto 2em;
}
.bottom-footer .footer-col a {
  font-size: 1em;
  line-height: 1.5;
  display: block;
  font-weight: 700;
}
.bottom-footer .footer-col li a {
  padding: 0.25em 0;
}
.bottom-footer .footer-col .contact-field {
  line-height: 1.25;
  margin: 0.5em 0;
}
.footer-logo {
  max-width: 100%;
  width: 10em;
  height: 4em;
  object-fit: contain;
}
@media screen and (max-width: 37.49375em) {
  .bottom-footer {
    text-align: center;
  }
}
@media screen and (min-width: 37.5em) {
  .bottom-footer .middle,
  .bottom-footer .first {
    padding-right: 2em;
  }
}
@media screen and (max-width: 49.99375em) {
  .bottom-footer .bordered-col {
    border-top: 0.0625rem solid #bcbcbc;
    padding-top: 2em;
  }
}
@media screen and (min-width: 50em) {
  .bottom-footer .middle,
  .bottom-footer .first {
    padding-right: 2em;
  }
  .bottom-footer .bordered-col {
    border-left: 0.0625rem solid #bcbcbc;
    padding-left: 2em;
  }
}
.footer-blue {
  background-color: #084b5f !important;
  color: white;
}
.footer-blue a {
  color: white;
}
.top-menu-spacer {
  height: 0.0625rem;
}
#topp {
  display: block;
  height: 4em;
}
.top-menu {
  background-color: #fff;
  box-shadow: 0 1px 6px 4px rgba(0, 0, 0, 0.06);
  line-height: 1.5;
  padding: 1em;
}
.top-menu .top-logo {
  display: block;
}
.top-menu .top-logo img {
  display: block;
  height: 2.5em;
  object-fit: contain;
}
.top-menu .top-logo.desktop-logo {
  float: left;
  max-width: 25%;
}
.top-menu .top-logo.desktop-logo img {
  width: 8em;
  height: 2em;
}
.top-menu .top-logo.mob-logo {
  max-width: 50%;
  float: left;
}
.top-menu .top-logo.mob-logo img {
  width: 3.5em;
}
.top-menu .menu {
  display: block;
  margin: 0;
  padding: 0;
}
.top-menu .menu li {
  text-align: left;
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.top-menu .menu li span,
.top-menu .menu li a,
.top-menu .menu li a:visited {
  display: block;
  text-decoration: none;
}
.top-menu .menu li a {
  font-weight: 400;
}
.top-menu .menu li.ancestor > a,
.top-menu .menu li.active > a {
  font-weight: 700;
}
.top-menu .menu > li .submenu {
  background-color: #f4f4f4;
  padding: 0 0 0.0625rem 0;
}
.top-menu .menu > li .submenu li {
  margin: 0;
  padding: 0.0625rem 0.0625rem 0 0.0625rem;
  list-style: none;
}
.top-menu .menu > li .submenu a {
  display: block;
  border-left-width: 0.5em;
  border-left-style: solid;
  border-left-color: #f4f4f4;
  padding: 0.5em;
  background-color: #fff;
  color: #444;
}
.top-menu .menu > li .submenu a:active,
.top-menu .menu > li .submenu a:hover {
  border-left-color: #0081d4;
  color: #0081d4;
}
.top-menu .menu > li .submenu li.active a {
  border-left-color: #444;
}
.top-menu .menu-toggler {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}
.top-menu .menu-toggler svg {
  width: 3em;
  height: 3em;
  display: block;
  cursor: pointer;
}
.top-menu .menu-toggler svg rect {
  fill: currentColor;
}
.top-menu .menu-toggler svg .splitBar1,
.top-menu .menu-toggler svg .splitBar2 {
  transform-origin: 50% 50%;
  transition: transform 500ms;
}
.top-menu .menu-toggler svg .topBar {
  transform-origin: 0.71875em 0.9375em;
  transition: transform 500ms;
}
.top-menu .menu-toggler svg .fadeBar,
.top-menu .menu-toggler svg .midBar {
  transition: fill-opacity 500ms;
}
.top-menu .menu-toggler svg .botBar {
  transform-origin: 0.71875em 2.0625em;
  transition: transform 500ms;
}
.menu-visible .top-menu .menu-toggler svg .splitBar1,
.menu-visible .top-menu .menu-toggler svg .topBar {
  transform: rotate(45deg);
}
.menu-visible .top-menu .menu-toggler svg .fadeBar,
.menu-visible .top-menu .menu-toggler svg .midBar {
  fill-opacity: 0;
}
.menu-visible .top-menu .menu-toggler svg .splitBar2,
.menu-visible .top-menu .menu-toggler svg .botBar {
  transform: rotate(-45deg);
}
@media screen and (max-width: 65.61875em) {
  .top-menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }
  .top-menu .top-logo.desktop-logo {
    max-width: 50%;
  }
  .top-menu .menu {
    display: none;
    max-width: 35em;
    margin: 0 auto;
    background: transparent;
  }
  .top-menu .menu span,
  .top-menu .menu a,
  .top-menu .menu a:visited {
    color: #fff;
  }
  .top-menu .menu span:hover,
  .top-menu .menu a:hover,
  .top-menu .menu a:visited:hover,
  .top-menu .menu span:active,
  .top-menu .menu a:active,
  .top-menu .menu a:visited:active {
    color: #0081d4;
    background-color: #fff;
  }
  .top-menu .menu > li a {
    font-size: 1.375em;
    line-height: 1.5em;
    margin: 0.875rem 0;
    padding: 0.5rem;
  }
  .top-menu .menu > li .submenu {
    display: none;
  }
  .top-menu .toggler-bar {
    height: 2em;
    box-sizing: border-box;
  }
  .menu-visible .top-menu {
    background-color: #4e7488;
    color: #fff;
    min-height: 70vh;
  }
  .menu-visible .top-menu .top-logo {
    display: none !important;
  }
  .menu-visible .top-menu .menu {
    margin-top: 1em;
    display: block;
  }
}
@media screen and (min-width: 65.625em) {
  .top-menu-spacer {
    display: block;
    height: 4em;
  }
  .top-menu-spacer.negmargin {
    margin-top: -4em;
    height: 5em;
  }
  .top-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 1em 1em;
  }
  .top-menu .top-logo.desktop-logo {
    margin-right: 2.5em;
  }
  .top-menu .toggler-bar {
    display: none !important;
  }
  .top-menu .menu span,
  .top-menu .menu a,
  .top-menu .menu a:visited {
    color: #444;
    background-color: #fff;
  }
  .top-menu .menu span:hover,
  .top-menu .menu a:hover,
  .top-menu .menu a:visited:hover,
  .top-menu .menu span:active,
  .top-menu .menu a:active,
  .top-menu .menu a:visited:active {
    color: #0081d4;
    background-color: #fff;
  }
  .top-menu .menu > li {
    position: relative;
    z-index: 1;
  }
  .top-menu .menu > li > a {
    padding: 0.25em 2em 0.25em 0;
  }
  .top-menu .menu > li.has_submenu > a {
    width: 8em;
    padding-left: 1em;
    padding-right: 1em;
  }
  .top-menu .menu > li .submenu {
    position: absolute;
    z-index: 3;
    top: 2.5em;
    left: 0;
    width: 10em;
    box-shadow: 0.1em 0.4em 0.6em rgba(0, 0, 0, 0.1);
    margin: 0;
    opacity: 0;
    transition: opacity 600ms;
    transition-delay: 300ms;
  }
  .top-menu .menu > li .submenu:empty {
    display: none;
  }
  .top-menu .menu > li .submenu li,
  .top-menu .menu > li .submenu a {
    display: block;
    max-width: 100%;
    width: auto;
  }
  .top-menu .menu > li:hover {
    z-index: 2;
  }
  .top-menu .menu > li:hover .submenu {
    z-index: 4;
    opacity: 1;
    transition-delay: 1ms;
    transition-duration: 200ms;
  }
  .top-menu .menu li {
    display: inline-block;
  }
  .top-menu .menu .home-link {
    display: none !important;
  }
}
.in-page-submenu {
  clear: both;
}
.in-page-submenu ul {
  background-color: #fff;
  padding: 0 0 0.0625rem 0;
  margin: 1em 0;
}
.in-page-submenu ul li {
  margin: 0;
  padding: 0.0625rem 0.0625rem 0 0.0625rem;
  list-style: none;
}
.in-page-submenu ul a {
  display: block;
  border-left-width: 0.5em;
  border-left-style: solid;
  border-left-color: #fff;
  padding: 0.5em;
  background-color: #fff;
  color: #444;
}
.in-page-submenu ul a:active,
.in-page-submenu ul a:hover {
  border-left-color: #0081d4;
  color: #0081d4;
}
.in-page-submenu ul li.active a {
  border-left-color: #444;
}
.in-page-submenu ul h4 {
  margin: 0;
}
.links-are-buttons a,
.button,
.textinput,
label,
button,
input,
select,
textarea {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-size: 1em;
}
.links-are-buttons a,
a.button,
a.button:visited,
.button,
button,
input[type="submit"],
input[type="button"] {
  border: 0;
  padding: 0.8em 1.5em;
  text-decoration: none;
  color: #fff;
  background-color: #4e7488;
  box-sizing: border-box;
  display: inline-block;
  margin: 0.5em 0;
  cursor: pointer;
  user-select: none;
  border-radius: 0.3125em;
}
.links-are-buttons a:visited,
a.button:visited,
a.button:visited:visited,
.button:visited,
button:visited,
input[type="submit"]:visited,
input[type="button"]:visited {
  color: #fff;
}
.links-are-buttons a.gray,
a.button.gray,
a.button:visited.gray,
.button.gray,
button.gray,
input[type="submit"].gray,
input[type="button"].gray {
  background-color: #d0dadb;
  color: #444;
}
.links-are-buttons a:hover,
a.button:hover,
a.button:visited:hover,
.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
.links-are-buttons a:focus,
a.button:focus,
a.button:visited:focus,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="button"]:focus,
.links-are-buttons a:active,
a.button:active,
a.button:visited:active,
.button:active,
button:active,
input[type="submit"]:active,
input[type="button"]:active {
  color: #fff;
  background-color: #0081d4;
}
select,
textarea,
.textinput,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="url"] {
  padding: 1em;
  line-height: 1.5;
  margin: 0.5em 0;
  display: block;
  width: 100%;
  border: 0.125rem solid #8a9093;
  border-radius: 0.3125em;
}
.has-error select,
.has-error textarea,
.has-error .textinput,
.has-error input[type="text"],
.has-error input[type="password"],
.has-error input[type="email"],
.has-error input[type="number"],
.has-error input[type="url"] {
  border-color: #eb5a3e;
}
input[type="file"].form-control {
  padding: 1em 0;
  width: 100%;
}
textarea,
select[multiple] {
  height: auto;
  min-height: 10em;
}
.spacer-star,
.required-star {
  visibility: hidden;
}
.has-error .required-star {
  color: #eb5a3e;
  visibility: visible;
}
.inputwrap {
  margin-bottom: 2em;
}
.inputwrap label {
  display: inline-block;
  padding: 0.5em 0;
  min-width: 8em;
}
.inputwrap label.control-label {
  font-weight: 700;
}
.inputwrap .form-control {
  margin-top: 0;
}
.inputwrap ul,
.inputwrap li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.inputwrap ul.messages {
  margin: 0.2em;
  padding: 0;
  font-size: 0.875em;
}
.inputwrap ul.messages li {
  list-style-type: none;
  margin: 0 0 0.5em;
  padding: 0;
}
.inputwrap ul.messages li strong {
  color: #eb5a3e;
}
.bg-offwhite {
  background-color: #f4f4f4;
}
.form-page {
  max-width: 30em;
  margin: 0 auto;
}
input[type="text"].jscolor {
  text-align: center;
}
.form-page.form-page-wide-desc {
  max-width: 40em;
}
@media screen and (min-width: 65.625em) {
  .form-page.form-page-wide {
    max-width: 64em;
  }
  .form-page.form-page-wide .inputwrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
  }
  .form-page.form-page-wide .inputwrap .innerMessagesWrap,
  .form-page.form-page-wide .inputwrap .inputInnerWrap,
  .form-page.form-page-wide .inputwrap .innerLabelWrap {
    flex: 1 1 17em;
  }
  .form-page.form-page-wide .inputwrap .inputInnerWrap {
    flex-basis: 30em;
    max-width: 30em;
  }
  .form-page.form-page-wide .inputwrap label.control-label {
    display: block;
    text-align: right;
    padding-top: 1em;
    padding-right: 1em;
    padding-bottom: 0;
  }
  .form-page.form-page-wide .inputwrap .messages {
    padding-left: 1em;
  }
}
.thank_you_bg {
  min-height: 70vh;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 70%;
  margin-bottom: -5.25em;
  padding-bottom: 1em;
}
button,
select,
textarea,
input {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-size: 100%;
}
textarea {
  display: block;
  width: 100%;
  padding: 1em;
}
input[type="button"],
input[type="submit"],
a.button,
.button,
button {
  padding: 0.5em 1em;
  margin: 1em auto;
  background-color: #4e7488;
  color: #fff;
  border: 0.125rem solid #4e7488;
  display: inline-block;
}
input[type="button"].block-button,
input[type="submit"].block-button,
a.button.block-button,
.button.block-button,
button.block-button {
  display: block;
  width: 100%;
}
input[type="button"].disabled,
input[type="submit"].disabled,
a.button.disabled,
.button.disabled,
button.disabled,
input[type="button"]:disabled,
input[type="submit"]:disabled,
a.button:disabled,
.button:disabled,
button:disabled {
  background-color: #f4f4f4;
  color: #8a9093;
  border-color: #f4f4f4;
}
input[type="button"]:hover,
input[type="submit"]:hover,
a.button:hover,
.button:hover,
button:hover,
input[type="button"]:active,
input[type="submit"]:active,
a.button:active,
.button:active,
button:active,
input[type="button"]:focus,
input[type="submit"]:focus,
a.button:focus,
.button:focus,
button:focus {
  background-color: #0081d4;
  color: #fff;
  border-color: #0081d4;
}
a:hover .button,
a:active .button,
a:focus .button {
  background-color: #0081d4;
  color: #fff;
  border-color: #0081d4;
}
.errorlist {
  margin: 0.25em 0;
  color: #eb5a3e;
}
.infobox {
  position: relative;
  z-index: 1;
  padding: 2em;
  margin: 1em auto;
  max-width: 25em;
}
.infobox p:empty {
  display: none;
}
.infobox.gallerybox {
  margin-top: 0;
  margin-bottom: 0;
}
@media screen {
  .infobox {
    background-color: #4e7488;
    color: #fff;
  }
  .infobox.dbg {
    color: #fff;
  }
  .infobox.lbg {
    color: #444;
  }
  .infobox.lcolorbg {
    color: #000;
  }
  .infobox.scheme-gray {
    background-color: #969696;
  }
  .infobox.scheme-black {
    background-color: #444;
  }
  .infobox.scheme-light-gray {
    background-color: #f4f4f4;
  }
  .infobox.scheme-white {
    background-color: #fff;
    border: 0.0625rem solid #d0dadb;
  }
}
@media screen and (min-width: 50em) {
  .infobox.centered {
    max-width: 93.5%;
    margin: 0 auto;
  }
  .infobox.fullw {
    max-width: 102%;
  }
  .infobox.floatleft,
  .infobox.floatright {
    z-index: 1;
    position: relative;
  }
  .infobox.floatleft {
    max-width: 34%;
    float: left;
    clear: left;
    margin-right: 5em;
  }
  .infobox.floatright {
    max-width: 34%;
    float: right;
    clear: right;
    margin-left: 5em;
  }
}
@media screen and (min-width: 65.625em) {
  .infobox.centered {
    max-width: 68%;
    margin: 0 auto;
  }
  .infobox.floatleft {
    max-width: 29.75%;
  }
  .infobox.floatright {
    max-width: 29.75%;
  }
}
.article-faq-item {
  padding: 0 0 1.125em;
  margin-bottom: 1.125em;
  border-bottom: 1px solid #ebebeb;
}
.article-faq-item h4.question {
  font-weight: 400;
  letter-spacing: 0.0125rem;
  line-height: 1.5;
}
.article-faq-item .submitted-date,
.article-faq-item .answered-date {
  color: #797979;
  margin: 0.375em 0;
  display: block;
}
.article-faq-item .answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}
.article-faq-item .bottom-toggler {
  margin-top: -1.7em;
  transition: margin-top 0.5s;
}
.article-faq-item .show-less,
.article-faq-item .show-more {
  background-color: #fff;
  color: #4e7488;
  font-weight: 700;
  letter-spacing: 0.025rem;
  cursor: pointer;
}
.article-faq-item .show-less:active,
.article-faq-item .show-more:active,
.article-faq-item .show-less:hover,
.article-faq-item .show-more:hover {
  color: #0081d4;
}
.article-faq-item .show-less {
  display: none;
}
.article-faq-item.expanded .bottom-toggler {
  margin-top: 0;
}
.article-faq-item.expanded .answer {
  max-height: none;
}
.article-faq-item.expanded .show-less {
  display: inline;
}
.article-faq-item.expanded .show-more {
  display: none;
}
.question-form {
  margin: 2em 0;
}
.question-form .pseudorow .the-description {
  margin-bottom: 1em;
}
.question-form .rich-text p {
  margin: 0 0 0.5em;
}
.question-form .rich-text i {
  font-style: normal;
  color: #797979;
  font-size: 0.875em;
}
@media screen and (min-width: 50em) {
  .question-form .pseudorow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .question-form .pseudorow.centered {
    align-items: center;
  }
  .question-form .pseudorow .the-link,
  .question-form .pseudorow .the-helptext,
  .question-form .pseudorow .the-description,
  .question-form .pseudorow .the-form {
    flex: 0 1 46%;
  }
  .question-form .pseudorow .the-form {
    order: 1;
  }
  .question-form .pseudorow .the-helptext,
  .question-form .pseudorow .the-description {
    order: 2;
  }
  .question-form .pseudorow .the-helptext {
    vertical-align: middle;
  }
  .question-form .pseudorow .the-link {
    order: 3;
  }
}
.flex-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flex-gallery .flex-gallery-cell {
  flex: 1 1 auto;
}
.flex-gallery .flex-gallery-cell .infobox {
  box-sizing: border-box;
  height: 100%;
}
.gallery-cell {
  margin: 0 auto;
  padding-top: 1em;
}
.auto2col > .gallery-cell {
  max-width: 32em;
}
.gallery-cell .desc {
  font-size: 1em;
  margin-top: 0.25em;
  text-align: center;
}
.gallery-cell .imageblock {
  margin: 0 !important;
}
.gallery-cell .gallery-item-title {
  margin-top: 0.5rem;
  display: block;
  text-align: center;
}
.gallery-cell img {
  width: 100%;
  object-fit: scale-down;
}
.gallery-cell .uncropped.portrait-mode,
.gallery-cell .portrait {
  max-width: 15em;
}
.gallery-cell .uncropped.tall-mode,
.gallery-cell .square,
.gallery-cell .circular {
  max-width: 17em;
}
.block-gallery > div {
  margin: 1em auto 2em;
}
@media screen and (min-width: 37.5em) {
  .flex-gallery.max-3cols .flex-gallery-cell,
  .flex-gallery.max-2cols .flex-gallery-cell {
    margin: 0 0 1em;
    flex-basis: 48%;
    flex-grow: 0;
  }
}
@media screen and (min-width: 65.625em) {
  .flex-gallery.max-3cols .flex-gallery-cell {
    flex-basis: 31%;
  }
  .flex-gallery.max-2cols .flex-gallery-cell {
    flex-basis: 48%;
  }
}
.extrawide-content {
  clear: both;
}
.article-content .extrawide-content {
  margin-bottom: 0 !important;
}
.article-content .extrawide-content .related-tiles {
  margin: 0 -1em;
  padding: 1.5em 1.5em 0;
  background-color: #ffffff;
  border-top: 0.125rem solid #ffffff;
  border-bottom: 0.125rem solid #ffffff;
}
.template-questionform .block-questionform,
.template-homepage .block-questionform,
.category-description .block-questionform {
  background-color: #f4f4f4;
  padding: 0.0625rem 2em;
}
.article-footer,
.article-content {
  background-color: #fff;
}
.article-footer {
  padding: 1em 1em;
  margin-bottom: 3em;
  margin-top: -4em;
}
.imageblock.fullw img {
  width: 100%;
}
.template-homepage .block-paragraph,
.stripped-page-content .block-paragraph,
.article-content .block-paragraph {
  margin: 0.5em 0 0.5em;
}
.template-homepage .pullquote,
.stripped-page-content .pullquote,
.article-content .pullquote,
.template-homepage .imageblock,
.stripped-page-content .imageblock,
.article-content .imageblock {
  margin: 0.5em -1em 1em;
}
.template-homepage .imageblock .caption,
.stripped-page-content .imageblock .caption,
.article-content .imageblock .caption {
  margin: 0.25em 1em 2em;
}
.template-homepage .imageblock .caption .captiontext,
.stripped-page-content .imageblock .caption .captiontext,
.article-content .imageblock .caption .captiontext,
.template-homepage .imageblock .caption .sourcetext,
.stripped-page-content .imageblock .caption .sourcetext,
.article-content .imageblock .caption .sourcetext {
  text-align: center;
  display: block;
  font-size: 0.875em;
}
.template-homepage .imageblock .caption .captiontext,
.stripped-page-content .imageblock .caption .captiontext,
.article-content .imageblock .caption .captiontext {
  font-weight: 400;
}
.template-homepage .imageblock .caption .sourcetext,
.stripped-page-content .imageblock .caption .sourcetext,
.article-content .imageblock .caption .sourcetext {
  font-weight: 300;
}
.template-homepage .imageblock img,
.stripped-page-content .imageblock img,
.article-content .imageblock img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
.template-homepage .imageblock .uncropped.portrait-mode,
.stripped-page-content .imageblock .uncropped.portrait-mode,
.article-content .imageblock .uncropped.portrait-mode,
.template-homepage .imageblock .portrait,
.stripped-page-content .imageblock .portrait,
.article-content .imageblock .portrait {
  max-width: 19em;
}
.template-homepage .imageblock .small-portrait,
.stripped-page-content .imageblock .small-portrait,
.article-content .imageblock .small-portrait {
  max-width: 12em;
}
.template-homepage .imageblock .uncropped.tall-mode,
.stripped-page-content .imageblock .uncropped.tall-mode,
.article-content .imageblock .uncropped.tall-mode,
.template-homepage .imageblock .square,
.stripped-page-content .imageblock .square,
.article-content .imageblock .square,
.template-homepage .imageblock .circular,
.stripped-page-content .imageblock .circular,
.article-content .imageblock .circular {
  max-width: 20em;
}
.template-homepage .imageblock.fullw img,
.stripped-page-content .imageblock.fullw img,
.article-content .imageblock.fullw img {
  width: 100%;
}
@media screen and (min-width: 20.625em) and (max-width: 49.99375em) {
  .template-homepage .imageblock.floatleft.cr-small-portrait,
  .stripped-page-content .imageblock.floatleft.cr-small-portrait,
  .article-content .imageblock.floatleft.cr-small-portrait,
  .template-homepage .imageblock.floatright.cr-small-portrait,
  .stripped-page-content .imageblock.floatright.cr-small-portrait,
  .article-content .imageblock.floatright.cr-small-portrait {
    max-width: 45%;
    margin-left: 1em;
    margin-right: 1em;
  }
  .template-homepage .imageblock.floatleft.cr-small-portrait,
  .stripped-page-content .imageblock.floatleft.cr-small-portrait,
  .article-content .imageblock.floatleft.cr-small-portrait {
    float: left;
    margin-left: 0;
  }
  .template-homepage .imageblock.floatright.cr-small-portrait,
  .stripped-page-content .imageblock.floatright.cr-small-portrait,
  .article-content .imageblock.floatright.cr-small-portrait {
    float: right;
    margin-right: 0;
  }
}
.template-homepage .imageblock.floatleft.cr-small-portrait,
.stripped-page-content .imageblock.floatleft.cr-small-portrait,
.article-content .imageblock.floatleft.cr-small-portrait {
  margin-left: 0 !important;
}
.template-homepage .imageblock.floatright.cr-small-portrait,
.stripped-page-content .imageblock.floatright.cr-small-portrait,
.article-content .imageblock.floatright.cr-small-portrait {
  margin-right: 0 !important;
}
.template-homepage .pullquote,
.stripped-page-content .pullquote,
.article-content .pullquote {
  border-width: 0 0 0 0.5rem;
  border-style: solid;
  border-color: #b2c7d6;
  margin: 2em 0;
  padding: 0 1em 0.5em;
}
.template-homepage .pullquote.has-img,
.stripped-page-content .pullquote.has-img,
.article-content .pullquote.has-img {
  margin-bottom: 4em !important;
}
.template-homepage .pullquote .quote-sym,
.stripped-page-content .pullquote .quote-sym,
.article-content .pullquote .quote-sym {
  width: 1.5em;
  height: 1.5em;
  color: #b2c7d6;
}
.template-homepage .pullquote blockquote,
.stripped-page-content .pullquote blockquote,
.article-content .pullquote blockquote {
  margin: 0;
  padding: 0;
}
.template-homepage .pullquote blockquote p:last-child,
.stripped-page-content .pullquote blockquote p:last-child,
.article-content .pullquote blockquote p:last-child {
  margin-bottom: 0;
}
.template-homepage .pullquote .cite,
.stripped-page-content .pullquote .cite,
.article-content .pullquote .cite {
  display: block;
  margin: 1.5em 0 0.25em;
}
.template-homepage .pullquote .timeorplace,
.stripped-page-content .pullquote .timeorplace,
.article-content .pullquote .timeorplace {
  display: block;
  color: #797979;
}
.template-homepage .pullquote .cite-image,
.stripped-page-content .pullquote .cite-image,
.article-content .pullquote .cite-image {
  border-radius: 50%;
  float: right;
  width: 5.6em;
  height: 5.6em;
  margin-top: 1.5em;
}
.template-homepage .pullquote.centered blockquote,
.stripped-page-content .pullquote.centered blockquote,
.article-content .pullquote.centered blockquote,
.template-homepage .pullquote.centered .cite-image-wrap,
.stripped-page-content .pullquote.centered .cite-image-wrap,
.article-content .pullquote.centered .cite-image-wrap {
  max-width: 55em;
}
@media screen {
  .stripped-page-content,
  .article-content {
    padding: 1em 1em;
    margin-bottom: 3em;
  }
  .stripped-page-content h1,
  .article-content h1 {
    color: #4e7488;
  }
}
.stripped-page-content .category-name,
.article-content .category-name {
  font-size: 1.25em;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
.stripped-page-content .image-byline,
.article-content .image-byline {
  display: block;
  text-align: center;
  font-size: 0.875em;
}
.stripped-page-content .file-list-block,
.article-content .file-list-block {
  margin: 1em 0;
  text-align: center;
}
.stripped-page-content .block-gallery .floatleft,
.article-content .block-gallery .floatleft,
.stripped-page-content .block-gallery .floatright,
.article-content .block-gallery .floatright,
.stripped-page-content .employee-list-block,
.article-content .employee-list-block {
  margin: 1em 3em;
}
.template-homepage .pullquote,
.template-homepage .imageblock {
  margin-left: 0;
  margin-right: 0;
}
.template-homepage .pullquote.floatleft,
.template-homepage .imageblock.floatleft {
  max-width: 59%;
  margin-right: auto;
  margin-left: 0;
}
.template-homepage .pullquote.floatleft img,
.template-homepage .imageblock.floatleft img,
.template-homepage .pullquote.floatleft .imgrestrictor,
.template-homepage .imageblock.floatleft .imgrestrictor {
  margin-left: 0;
}
.template-homepage .pullquote.floatright,
.template-homepage .imageblock.floatright {
  max-width: 59%;
  margin-left: auto;
  margin-right: 0;
}
.template-homepage .pullquote.floatright img,
.template-homepage .imageblock.floatright img,
.template-homepage .pullquote.floatright .imgrestrictor,
.template-homepage .imageblock.floatright .imgrestrictor {
  margin-right: 0;
}
.article-indexbox,
.article-menutile {
  display: block;
  width: 13em;
  max-width: 100%;
  margin: 2em auto;
}
.article-indexbox .tileimage,
.article-menutile .tileimage {
  width: 100%;
  height: 7em;
  background-color: #e0e0e0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.article-indexbox h5,
.article-menutile h5 {
  opacity: 0.72;
  margin: 0.6em 0 0.4em;
  max-height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}
.article-indexbox:hover h5,
.article-menutile:hover h5 {
  text-decoration: underline;
}
.article-indexbox .excerpt,
.article-menutile .excerpt {
  line-height: 1.5;
  max-height: 4.5em;
  overflow: hidden;
  display: block;
}
.article-indexbox {
  width: 100%;
  margin: 1em 0;
  font-weight: 400;
}
.article-indexbox .the-link {
  font-weight: 700;
  font-size: 112.5%;
}
.indexbox {
  background-color: #fff;
  padding: 1em;
  margin-bottom: 2em;
}
.indexbox.forumbox {
  padding: 0 1em 1.5em;
  margin-bottom: 0;
}
.tag-cloud,
.index-links {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2em;
}
.tag-cloud::after,
.index-links::after {
  content: " ";
  flex: 4 1 auto;
  height: 1em;
}
.tag-cloud .taglink {
  flex: 0.2 1 auto;
  margin-right: 1em;
}
.index-links .inlineblock {
  display: inline-block;
  padding: 0;
  margin: 1rem 0;
  flex: 0 1 auto;
}
.index-links .inlineblock.title {
  margin-right: 1em;
  font-weight: 400;
}
.index-links .inlineblock .article-type {
  padding: 0 0.5rem;
  margin-right: 1em;
}
.indexbox .fancylink,
.index-links .fancylink {
  margin: 2em 1em 0;
  display: inline-block;
}
.article-compact-item,
.article-tile {
  margin-bottom: 5em;
  display: block;
  background-color: #fff;
  box-shadow: 0 0.125rem 2em 0 rgba(78, 116, 136, 0.1);
  transition: box-shadow 0.5s;
}
.article-compact-item:active,
.article-tile:active,
.article-compact-item:hover,
.article-tile:hover {
  box-shadow: 0 0.125rem 2em 0.5em rgba(78, 116, 136, 0.4);
}
.article-compact-item:active .behavelink,
.article-tile:active .behavelink,
.article-compact-item:hover .behavelink,
.article-tile:hover .behavelink {
  color: #0081d4;
}
.article-compact-item .tileimage,
.article-tile .tileimage {
  height: 12em;
  background-color: #e0e0e0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.front-main-article .article-compact-item .tileimage,
.front-main-article .article-tile .tileimage {
  height: 20em;
}
.article-compact-item p.abovetitle,
.article-tile p.abovetitle,
.article-compact-item p.belowtitle,
.article-tile p.belowtitle {
  margin: 0;
  font-weight: 700;
}
.article-compact-item p.abovetitle,
.article-tile p.abovetitle {
  margin-bottom: 0.5em;
}
.article-compact-item p.belowtitle,
.article-tile p.belowtitle {
  margin-top: 0.5em;
}
.article-compact-item .no-subtitle,
.article-tile .no-subtitle {
  margin: 0.75em 0;
}
.article-compact-item .thetext,
.article-tile .thetext {
  padding: 0.5em 2em 0.0625rem;
  background-color: #fff;
  color: #444;
}
.article-compact-item {
  margin-bottom: 2em;
}
.article-compact-item .thetext {
  padding: 1em 2em;
  min-height: 10em;
}
.article-compact-item .thetext h6 {
  margin-bottom: 0.5em;
}
.article-compact-item .tileimage {
  height: 10em;
}
.indexbox-feature,
.newsletterbox,
a.featurebanner,
.featurebox {
  margin-bottom: 2em;
}
.pseudo-article-tile .thetext {
  padding: 0.0625rem 1em;
}
.pseudo-article-tile .thetext .headings {
  padding: 0 1em 2em;
}
.pseudo-article-tile .thetext .fancytable {
  margin-top: 0;
  margin-bottom: 1em;
}
@media screen and (min-width: 50em) and (max-width: 65.61875em) {
  .newsletterbox h4 {
    font-size: 1.2em;
  }
}
@media screen and (min-width: 37.5em) {
  .article-tile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .article-tile .spacer,
  .article-tile .tileimage,
  .article-tile .thetext {
    flex: 1 1 65%;
    box-sizing: border-box;
  }
  .article-tile .tileimage {
    flex-basis: 50%;
  }
  .article-tile .spacer {
    flex-grow: 0;
    flex-basis: 3em;
    height: 1em;
  }
  .odd .article-tile {
    flex-direction: row-reverse;
  }
  .article-compact-item {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
  }
  .article-compact-item .tileimage,
  .article-compact-item .thetext {
    flex: 1 1 70%;
    box-sizing: border-box;
  }
  .article-compact-item .tileimage {
    flex-basis: 32%;
    height: auto;
  }
}
@media screen and (max-width: 37.49375em) {
  .article-tile .tileimage {
    height: 50vw;
  }
  .article-tile .thetext {
    padding: 0.5em 2em 0.0625rem;
  }
  .bookmarkbox {
    display: none;
  }
}
@media screen and (min-width: 20.625em) and (max-width: 49.99375em) {
  .bookmarkbox {
    display: none;
  }
  .stripped-page-content .image-byline,
  .article-content .image-byline {
    text-align: right;
    margin-bottom: -1em;
  }
}
@media screen and (max-width: 65.61875em) {
  .bookmarkbox .bookmarkstatus {
    min-width: 3em;
  }
  .bookmarkbox b {
    display: none !important;
  }
}
@media screen and (min-width: 50em) {
  .bookmarkbox {
    text-align: right;
    width: 9em;
  }
  .article-indexbox .tileimage {
    float: left;
    width: 10em;
  }
  .article-indexbox .thetext {
    margin-left: 12em;
  }
  .pseudo-article-tile .tileimage,
  .article-tile .tileimage {
    height: 16em;
  }
  .front-main-article .pseudo-article-tile p.abovetitle,
  .front-main-article .article-tile p.abovetitle,
  .front-main-article .pseudo-article-tile p.belowtitle,
  .front-main-article .article-tile p.belowtitle {
    font-size: 1.2em;
  }
  .front-main-article .pseudo-article-tile div.short-desc,
  .front-main-article .article-tile div.short-desc {
    font-size: 112.5%;
  }
  .front-main-article .pseudo-article-tile .tileimage,
  .front-main-article .article-tile .tileimage {
    height: 22.5em;
  }
  .featurebox {
    min-height: 22.5em;
  }
  .featurebox .imgwrap {
    height: 11em;
    display: table-cell;
    vertical-align: middle;
  }
  a.featurebanner {
    min-height: 22.5em;
  }
  .newsletterbox {
    min-height: 14.5em;
  }
  .article-footer,
  .article-content {
    padding: 1em 4em;
  }
  .article-footer .file-list-block,
  .article-content .file-list-block,
  .article-footer .block-gallery .floatleft,
  .article-content .block-gallery .floatleft,
  .article-footer .block-gallery .floatright,
  .article-content .block-gallery .floatright,
  .article-footer .employee-list-block,
  .article-content .employee-list-block,
  .article-footer .extrawide-content,
  .article-content .extrawide-content {
    margin: 2em -2em;
  }
  .article-footer .pullquote,
  .article-content .pullquote,
  .article-footer .imageblock,
  .article-content .imageblock {
    margin: 0.5em -2em 2em;
  }
  .article-footer .pullquote.centered,
  .article-content .pullquote.centered {
    margin-left: 0;
    margin-right: 0;
    max-width: 90%;
  }
  .article-footer .extrawide-content .related-tiles,
  .article-content .extrawide-content .related-tiles {
    margin: 0 -2em;
  }
  .stripped-page-content,
  .article-content {
    position: relative;
  }
  .stripped-page-content .image-byline,
  .article-content .image-byline {
    position: absolute;
    top: 0.5em;
    right: 2.5em;
  }
  .stripped-page-content .file-list-block,
  .article-content .file-list-block {
    text-align: left;
  }
  .stripped-page-content .imageblock .caption,
  .article-content .imageblock .caption {
    margin: 0.5em 0;
    text-align: center;
  }
  .stripped-page-content .imageblock .caption .captiontext,
  .article-content .imageblock .caption .captiontext {
    display: inline-block;
  }
  .stripped-page-content .imageblock .caption .sourcetext,
  .article-content .imageblock .caption .sourcetext {
    display: inline-block;
    margin-left: 1em;
    margin-top: 0.25em;
  }
  .stripped-page-content .imageblock.fullw .caption,
  .article-content .imageblock.fullw .caption {
    text-align: left;
  }
  .stripped-page-content .imageblock.fullw .caption .sourcetext,
  .article-content .imageblock.fullw .caption .sourcetext {
    float: right;
  }
  .stripped-page-content .file-list-block.floatleft,
  .article-content .file-list-block.floatleft,
  .stripped-page-content .block-gallery > div.floatleft,
  .article-content .block-gallery > div.floatleft,
  .stripped-page-content .employee-list-block.floatleft,
  .article-content .employee-list-block.floatleft,
  .stripped-page-content .pullquote.floatleft,
  .article-content .pullquote.floatleft,
  .stripped-page-content .imageblock.floatleft,
  .article-content .imageblock.floatleft,
  .stripped-page-content .file-list-block.floatright,
  .article-content .file-list-block.floatright,
  .stripped-page-content .block-gallery > div.floatright,
  .article-content .block-gallery > div.floatright,
  .stripped-page-content .employee-list-block.floatright,
  .article-content .employee-list-block.floatright,
  .stripped-page-content .pullquote.floatright,
  .article-content .pullquote.floatright,
  .stripped-page-content .imageblock.floatright,
  .article-content .imageblock.floatright {
    max-width: 59%;
  }
  .stripped-page-content .file-list-block.floatleft,
  .article-content .file-list-block.floatleft,
  .stripped-page-content .block-gallery > div.floatleft,
  .article-content .block-gallery > div.floatleft,
  .stripped-page-content .employee-list-block.floatleft,
  .article-content .employee-list-block.floatleft,
  .stripped-page-content .pullquote.floatleft,
  .article-content .pullquote.floatleft,
  .stripped-page-content .imageblock.floatleft,
  .article-content .imageblock.floatleft {
    clear: left;
    margin-right: 3em;
    float: left;
  }
  .stripped-page-content .file-list-block.floatright,
  .article-content .file-list-block.floatright,
  .stripped-page-content .block-gallery > div.floatright,
  .article-content .block-gallery > div.floatright,
  .stripped-page-content .employee-list-block.floatright,
  .article-content .employee-list-block.floatright,
  .stripped-page-content .pullquote.floatright,
  .article-content .pullquote.floatright,
  .stripped-page-content .imageblock.floatright,
  .article-content .imageblock.floatright {
    clear: right;
    margin-left: 3em;
    float: right;
  }
  .stripped-page-content .pullquote.centered,
  .article-content .pullquote.centered {
    margin-left: 0;
    margin-right: 0;
  }
  .stripped-page-content .pullquote.floatleft,
  .article-content .pullquote.floatleft {
    border-width: 0 0.5rem 0 0;
    text-align: right;
  }
  .stripped-page-content .pullquote.floatleft .cite-image,
  .article-content .pullquote.floatleft .cite-image {
    float: left;
  }
  .stripped-page-content .block-gallery .floatleft,
  .article-content .block-gallery .floatleft,
  .stripped-page-content .block-gallery .floatright,
  .article-content .block-gallery .floatright {
    max-width: 50%;
  }
  .stripped-page-content .file-list-block .a-file-col,
  .article-content .file-list-block .a-file-col,
  .stripped-page-content .employee-list-block .a-file-col,
  .article-content .employee-list-block .a-file-col,
  .stripped-page-content .file-list-block .an-employee-col .employee-item,
  .article-content .file-list-block .an-employee-col .employee-item,
  .stripped-page-content .employee-list-block .an-employee-col .employee-item,
  .article-content .employee-list-block .an-employee-col .employee-item {
    max-width: 17em;
  }
  .stripped-page-content .file-list-block.floatleft,
  .article-content .file-list-block.floatleft,
  .stripped-page-content .employee-list-block.floatleft,
  .article-content .employee-list-block.floatleft,
  .stripped-page-content .file-list-block.floatright,
  .article-content .file-list-block.floatright,
  .stripped-page-content .employee-list-block.floatright,
  .article-content .employee-list-block.floatright {
    max-width: 18em;
  }
}
@media screen and (min-width: 65.625em) {
  .pseudo-article-tile .tileimage,
  .article-tile .tileimage {
    height: 21em;
  }
  .bookmarkbox .bookmarkstatus {
    min-width: 9em;
  }
  .article-footer,
  .article-content {
    padding: 1em 8em;
  }
  .article-footer .file-list-block,
  .article-content .file-list-block,
  .article-footer .block-gallery .floatleft,
  .article-content .block-gallery .floatleft,
  .article-footer .block-gallery .floatright,
  .article-content .block-gallery .floatright,
  .article-footer .employee-list-block,
  .article-content .employee-list-block,
  .article-footer .extrawide-content,
  .article-content .extrawide-content {
    margin: 2em -4em;
  }
  .article-footer .pullquote,
  .article-content .pullquote,
  .article-footer .imageblock,
  .article-content .imageblock {
    margin: 0.5em -4em 2em;
  }
  .article-footer .pullquote.centered,
  .article-content .pullquote.centered {
    margin-left: 0;
    margin-right: 0;
    max-width: 80%;
  }
  .article-footer .extrawide-content .related-tiles,
  .article-content .extrawide-content .related-tiles {
    margin: 0 -4em;
  }
  .article-footer .image-byline,
  .article-content .image-byline {
    right: 1.5em;
  }
  .featurebox .imgwrap {
    height: 11.5em;
  }
}
@media screen and (min-width: 78.125em) {
  .article-footer,
  .article-content {
    padding: 2em 10em;
  }
  .article-footer .file-list-block,
  .article-content .file-list-block,
  .article-footer .block-gallery .floatleft,
  .article-content .block-gallery .floatleft,
  .article-footer .block-gallery .floatright,
  .article-content .block-gallery .floatright,
  .article-footer .employee-list-block,
  .article-content .employee-list-block,
  .article-footer .extrawide-content,
  .article-content .extrawide-content {
    margin: 3em -5em;
  }
  .article-footer .pullquote,
  .article-content .pullquote,
  .article-footer .imageblock,
  .article-content .imageblock {
    margin: 0.5em -5em 3em;
  }
  .article-footer .pullquote.centered,
  .article-content .pullquote.centered {
    margin-left: 0;
    margin-right: 0;
    max-width: 70%;
  }
  .article-footer .extrawide-content .related-tiles,
  .article-content .extrawide-content .related-tiles {
    margin: 0 -5em;
  }
}
@media only screen and (max-width: 600px) {
  .article-compact-item .thetext {
    padding: 1em 1em !important;
  }
  .article-compact-item .tileimage {
    height: 12em;
  }
  .article-compact-item .thetext {
    min-height: 6em;
  }
}
.article-list-block p {
  margin: 0;
  font-size: 2em;
  text-align: end;
  margin: 0 1em 0em 0em;
}
@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  .article-compact-item .tileimage {
    flex-basis: 50%;
  }
  .bottom-footer .padded {
    display: flex;
  }
}
.toc-box {
  margin: 2em 0;
}
.toc-box.bordered {
  border-bottom: solid 0.125rem #ebebeb;
  padding-bottom: 1em;
}
.toc-box.bordered h3 {
  border-bottom: solid 0.125rem #ebebeb;
  padding-bottom: 0.5rem;
}
.toc-box h3 {
  margin: 0 0 1rem;
}
.toc-box .toc-el {
  display: block;
  font-size: 1.125em;
  padding: 0.5rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.toc-box .toc-el.toc-subsection {
  font-weight: 400;
  font-size: 1em;
}
.toc-box .toc-subsection + .toc-chapter {
  margin-top: 0.5em;
}
.toc-box.inline {
  margin: 0 0 0.5em;
}
.toc-box.inline .toc-el {
  display: inline-block;
  padding: 0.5em 1em;
}
.to-top-link {
  display: none;
}
@media screen and (min-width: 50em) {
  .has-toc .to-top-link {
    display: block;
  }
  .to-top-link {
    position: fixed;
    top: 4.5em;
    right: 1em;
    z-index: 100;
  }
  .to-top-link a {
    display: inline-block;
    font-size: 0.875em;
    padding: 0 0.5em;
    font-size: 1rem;
    vertical-align: middle;
  }
  .toc-box {
    margin: 1.5em auto 3em;
    min-width: 17em;
  }
  .toc-box.floatleft,
  .toc-box.floatright {
    z-index: 1;
    position: relative;
  }
  .toc-box.centered {
    min-width: 20em;
    max-width: 50%;
  }
  .toc-box.floatleft {
    max-width: 60%;
    float: left;
    clear: left;
    margin-left: 0;
    margin-right: 3em;
  }
  .toc-box.floatright {
    max-width: 60%;
    float: right;
    clear: right;
    margin-right: 0;
    margin-left: 3em;
  }
}
@media screen and (max-width: 65.61875em) {
  .to-top-link {
    top: 1.5em;
    z-index: 8;
  }
  .to-top-link a span {
    display: none;
  }
}
.subsection-block {
  clear: both;
}
.subsection-block .subsection-toggler {
  position: relative;
  display: block;
}
.subsection-block .subsection-toggler svg {
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  position: absolute;
  right: 0;
  top: 0;
}
.subsection-block .subsection-visible,
.subsection-block .subsection-hidden {
  display: none;
}
@media screen and (max-width: 49.99375em) {
  .subsection-block {
    border-bottom: 1px solid #ebebeb;
    padding: 0.5em 0;
  }
  .subsection-block .subs-title {
    line-height: 2rem;
    margin-bottom: 0;
    margin-top: 0;
  }
  .subsection-block .subsection-hidden {
    display: block;
  }
  .subsection-block .the-subsection {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }
  .subsection-block.expanded .subsection-visible {
    display: block;
  }
  .subsection-block.expanded .subsection-hidden {
    display: none;
  }
  .subsection-block.expanded .the-subsection {
    max-height: none;
  }
}
@media screen and (min-width: 50em) {
  .subsection-block .subs-title {
    line-height: 2;
    margin-bottom: 0;
    margin-top: 0;
  }
}
.reference-block ul,
.reference-block li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.reference-block li {
  position: relative;
}
.reference-block li .theanchor {
  position: absolute;
  top: -6em;
}
.reference-block li .theid {
  width: 3em;
  text-align: right;
  font-weight: 700;
  left: -2.5em;
  position: absolute;
  top: 0;
}
.reference-block li .theref {
  margin: 1rem 1.5em;
}
.reference-block li .theref p {
  font-size: 1em;
}
@media screen and (max-width: 49.99375em) {
  .reference-block li .theid {
    left: -0.8em;
  }
  .reference-block li .theref.withid {
    margin-left: 3em;
  }
}
.pagination {
  text-align: center;
  margin: 0;
  padding: 0;
}
.pagination::after {
  content: "_";
  clear: both;
  height: 0;
  display: block;
  overflow: hidden;
}
.pagination .next,
.pagination .prev {
  width: auto;
}
.pagination .prev {
  float: left;
}
.pagination .next {
  float: right;
}
.pagination li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;
}
.pagination li a {
  border: 0.125rem solid #4e7488;
  display: block;
  min-width: 2.25em;
  line-height: 2.25;
  margin: 0.25em;
  text-align: center;
}
.pagination li a svg {
  width: 2.25em;
  height: 2.25em;
  vertical-align: bottom;
  display: block;
}
.pagination li.active a,
.pagination li a:active,
.pagination li a:hover {
  border-color: #0081d4;
  background-color: #0081d4;
  color: #fff;
}
.pagination li.disabled a {
  background-color: transparent;
  color: #8a9093;
  border-color: #f4f4f4;
  cursor: default;
}
.social-media-wrap {
  margin-top: 1.5em;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
.social-media-wrap:empty {
  display: none;
}
.social-media-link {
  width: 2em;
  height: 2em;
  display: inline-block;
  margin: 0.25em;
  flex: 0 0 2em;
}
@media screen and (min-width: 50em) {
  .social-media-wrap {
    justify-content: space-between;
  }
}
.data-receipt {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1px 1em;
  margin: 1em -1em;
  box-shadow: 0.2em 0.5em 2em rgba(0, 0, 0, 0.1);
}
.data-receipt .imagePreview,
.data-receipt span.colorPreview {
  vertical-align: middle;
  display: inline-block;
  width: 4em;
  height: 4em;
  border: 1px solid #d0dadb;
}
.data-receipt .normaltext {
  vertical-align: top;
  display: inline-block;
  white-space: pre-line;
}
.data-receipt span.colorPreview {
  border-radius: 0.25em;
}
.data-receipt i.empty {
  opacity: 0.5;
}
.data-receipt .imagePreview {
  object-fit: contain;
  object-position: left center;
  min-height: 2em;
  max-height: 8em;
  height: auto;
  width: 19em;
  max-width: 100%;
  padding: 3px;
  background-color: #fff;
}
.data-receipt .thank-you-field-label {
  display: inline-block;
  width: 10em;
  font-size: 0.9em;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}
.data-receipt .thank-you-field-line {
  margin: 1em auto;
}
.widebanner {
  position: relative;
}
.widebanner .banner-bg-svg,
.widebanner .banner-bg {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.widebanner .banner-content {
  position: relative;
  z-index: 3;
}
.widebanner .banner-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.widebanner .banner-bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.widebanner .notsmallbanner,
.widebanner .smallbanner,
.widebanner .medbanner,
.widebanner .bigbanner {
  display: none;
}
@media screen and (max-width: 49.99375em) {
  .widebanner .smallbanner {
    display: block;
  }
}
@media screen and (min-width: 50em) and (max-width: 78.11875em) {
  .widebanner .notsmallbanner,
  .widebanner .medbanner {
    display: block;
  }
}
@media screen and (min-width: 78.125em) {
  .widebanner .notsmallbanner,
  .widebanner .bigbanner {
    display: block;
  }
}
.fancy-video {
  cursor: pointer;
  text-shadow: 0 0 1em rgba(255, 255, 255, 0.6);
}
.fancy-video .playbutton {
  width: 7em;
  height: 7em;
  color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3.5em;
  margin-left: -3.5em;
  z-index: 1;
}
.fancy-video .small-playbutton {
  width: 4em;
  height: 4em;
  color: rgba(0, 0, 0, 0.9);
}
.fancy-video:hover .playbutton {
  color: rgba(0, 0, 0, 0.9);
}
.fancy-video.dbg {
  color: #fff;
  background-color: #000;
  text-shadow: 0 0 1em rgba(0, 0, 0, 0.3);
}
.fancy-video.dbg .playbutton {
  color: rgba(255, 255, 255, 0.6);
}
.fancy-video.dbg .small-playbutton {
  color: #ffffff;
}
.fancy-video.dbg:hover .playbutton {
  color: #ffffff;
}
.fancy-video .overlay-text {
  box-sizing: border-box;
}
.fancy-video .banner-bg-svg {
  pointer-events: none;
}
.fancy-video .overlay-video {
  visibility: hidden;
  transition-property: opacity;
  transition-duration: 1s;
  opacity: 0;
  position: relative;
  z-index: 0;
  width: 150vh;
  max-width: 100%;
  margin: 0 auto;
}
.fancy-video .banner-bg,
.fancy-video .banner-bg-svg {
  transition-property: opacity;
  transition-duration: 1s;
}
.fancy-video.show-video .overlay-video {
  opacity: 1;
  visibility: visible;
  z-index: 6;
}
.fancy-video.show-video .overlay-text {
  display: none;
}
.fancy-video.show-video .banner-bg,
.fancy-video.show-video .banner-bg-svg {
  opacity: 0;
}
.image-cta,
.frontpage-cta {
  background-color: #f4f4f4;
  overflow: hidden;
}
.image-cta .banner-content,
.frontpage-cta .banner-content {
  display: flex;
  margin: 7em 0 1em;
}
.image-cta .banner-content .banner-text-content .inner-pad,
.frontpage-cta .banner-content .banner-text-content .inner-pad {
  padding: 1em 1.5em 1em 2em;
  max-width: 22em;
  float: left;
  width: auto;
}
.image-cta .banner-content .banner-pre-margin,
.frontpage-cta .banner-content .banner-pre-margin,
.image-cta .banner-content .banner-text-content .inner-pad,
.frontpage-cta .banner-content .banner-text-content .inner-pad {
  color: #4e7488;
  background-color: rgba(255, 255, 255, 0.9);
}
.image-cta .banner-content.dbg .banner-pre-margin,
.frontpage-cta .banner-content.dbg .banner-pre-margin,
.image-cta .banner-content.dbg .banner-text-content .inner-pad,
.frontpage-cta .banner-content.dbg .banner-text-content .inner-pad {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}
.image-cta .banner-content .banner-pre-margin,
.frontpage-cta .banner-content .banner-pre-margin,
.image-cta .banner-content .banner-post-margin,
.frontpage-cta .banner-content .banner-post-margin,
.image-cta .banner-content .banner-after-text,
.frontpage-cta .banner-content .banner-after-text,
.image-cta .banner-content .banner-text-content,
.frontpage-cta .banner-content .banner-text-content {
  flex: 1 1 auto;
}
.image-cta .banner-content .banner-text-content,
.frontpage-cta .banner-content .banner-text-content {
  flex-basis: 100%;
  flex-grow: 0;
}
.image-cta .banner-content .banner-post-margin,
.frontpage-cta .banner-content .banner-post-margin {
  flex-basis: 1em;
}
@media screen and (min-width: 37.5em) and (max-width: 49.99375em) {
  .image-cta .banner-content,
  .frontpage-cta .banner-content {
    margin-top: 9em;
  }
  .image-cta .banner-content .banner-text-content,
  .frontpage-cta .banner-content .banner-text-content {
    flex-basis: 37em;
  }
}
@media screen and (min-width: 50em) and (max-width: 65.61875em) {
  .image-cta .banner-content .banner-text-content,
  .frontpage-cta .banner-content .banner-text-content {
    flex-basis: 49em;
  }
}
@media (min-width: 50em) {
  .image-cta .banner-content,
  .frontpage-cta .banner-content {
    margin-bottom: 1.5em;
    margin-top: 10.75em;
  }
  .image-cta .banner-content .banner-text-content .inner-pad,
  .frontpage-cta .banner-content .banner-text-content .inner-pad {
    padding-left: 3em;
    max-width: 28em;
  }
}
@media (min-width: 65.625em) {
  .image-cta .banner-content .banner-text-content,
  .frontpage-cta .banner-content .banner-text-content {
    flex-basis: 60em;
  }
}
@media (min-width: 78.125em) {
  .image-cta .banner-content,
  .frontpage-cta .banner-content {
    margin-top: 13.75vw;
  }
}
.breadcrumb {
  margin: 0.5em 0;
  padding: 0 0em 0em 2em;
  line-height: 1.5;
}
.breadcrumb li {
  list-style-type: none;
  display: inline-block;
}
.breadcrumb li a {
  font-weight: 700;
}
.breadcrumb li svg.separator {
  width: 1em;
  height: 1.5em;
  vertical-align: bottom;
}
.breadcrumb li:last-child svg {
  display: none;
}
.breadcrumb li:last-child a {
  color: #444;
  font-weight: 400;
}
@media screen and (max-width: 49.99375em) {
  .breadcrumb {
    display: none;
  }
}
@media screen and (min-width: 50em) {
  .breadcrumb {
    float: left;
    margin-right: 2em;
  }
}
table td,
table th {
  text-align: left;
}
table td.number,
table th.number {
  text-align: right;
}
table td.center,
table th.center {
  text-align: center;
}
table td.colw1,
table th.colw1 {
  width: 6.5%;
}
table td.colw2,
table th.colw2 {
  width: 15%;
}
table td.colw3,
table th.colw3 {
  width: 23.5%;
}
table td.colw4,
table th.colw4 {
  width: 32%;
}
table td.colw5,
table th.colw5 {
  width: 40.5%;
}
table td.colw6,
table th.colw6 {
  width: 49%;
}
table td.colw7,
table th.colw7 {
  width: 57.5%;
}
table td.colw8,
table th.colw8 {
  width: 66%;
}
table td.colw9,
table th.colw9 {
  width: 74.5%;
}
table td.colw10,
table th.colw10 {
  width: 83%;
}
table td.colw11,
table th.colw11 {
  width: 91.5%;
}
.block-table table,
table.fancytable {
  width: 100%;
  margin: 1.5em 0;
}
.block-table table thead th,
table.fancytable thead th {
  font-weight: bold;
  border-bottom: 4px solid #4e7488;
}
.block-table table td,
table.fancytable td,
.block-table table th,
table.fancytable th {
  padding: 0.5em 1em;
  border: 1px solid #444;
}
@media screen {
  .block-table table thead th,
  table.fancytable thead th {
    color: #fff;
    background: #4e7488;
  }
  .block-table table tbody th,
  table.fancytable tbody th {
    color: #fff;
    background: #4e7488;
    border-right: 4px solid #4e7488;
  }
  .block-table table tbody tr:nth-child(odd) th,
  table.fancytable tbody tr:nth-child(odd) th {
    background-color: #638fa6;
  }
  .block-table table tbody tr:nth-child(even) th,
  table.fancytable tbody tr:nth-child(even) th {
    background-color: #578298;
  }
  .block-table table tr:nth-child(odd) td,
  table.fancytable tr:nth-child(odd) td {
    background-color: #fbfbfb;
  }
  .block-table table tr:nth-child(even) td,
  table.fancytable tr:nth-child(even) td {
    background-color: #ebebeb;
  }
}
.frontpage-widget .widget-slogan {
  margin-top: 2.5em;
}
.frontpage-widget .features {
  padding: 1em 1.5em 0.5em;
  box-shadow: 0 0.125rem 2em 0 rgba(78, 116, 136, 0.1);
  margin: 2.5em 0;
  background-color: #fff;
}
.frontpage-widget .feature {
  padding: 1.5em 0 0.5em;
  border-bottom: 1px solid #ebebeb;
}
.frontpage-widget .feature:last-child {
  border-bottom: 0;
}
.frontpage-widget .feature h6 {
  margin-top: 0;
}
.frontpage-widget .feature .the-text,
.frontpage-widget .feature h6 {
  position: relative;
  padding-right: 2.5em;
}
.frontpage-widget .feature .check-icon {
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 0;
  right: 0;
  color: #4e7488;
}
.frontpage-widget .feature .read-more-link {
  display: block;
  margin: 1em 0 0.75em;
}
@media screen and (min-width: 65.625em) {
  .frontpage-widget .widget-slogan {
    margin-right: 3em;
  }
  .frontpage-widget .feature .the-text,
  .frontpage-widget .feature h6 {
    padding-right: 4.375em;
  }
  .frontpage-widget .feature .check-icon {
    right: 1.875em;
  }
}
.fancylink-wrap.no-margin {
  margin: -1rem 0 0;
}
.fancylink-wrap.margin-above,
.fancylink-wrap.margin-below,
.fancylink-wrap.margin-both {
  margin: 2rem 0;
}
.fancylink-wrap.margin-above {
  margin-bottom: 0;
}
.fancylink-wrap.margin-below {
  margin-top: -1rem;
}
@media screen and (min-width: 65.625em) {
  .fancylink-wrap.margin-above,
  .fancylink-wrap.margin-below,
  .fancylink-wrap.margin-both {
    margin: 3rem 0;
  }
  .fancylink-wrap.margin-above {
    margin-bottom: 0;
  }
  .fancylink-wrap.margin-below {
    margin-top: -1rem;
  }
}
@media screen and (max-width: 37.49375em) {
  .wagtail-userbar {
    display: none !important;
  }
}
@media print {
  .top-menu,
  .top-menu-spacer,
  .bottom-footer,
  .article-footer,
  .noprint {
    display: none !important;
  }
  body {
    margin: 0;
    font-size: 12pt;
  }
  h1 {
    margin-top: 1.5rem;
    font-size: 2em;
  }
  h2 {
    font-size: 1.8em;
  }
  h3 {
    font-size: 1.6em;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1em;
  }
  h6,
  .ingress {
    font-size: 1.3em;
  }
  .infobox {
    border: 1pt solid gray;
  }
  @page {
    size: auto;
    margin: 20mm 25mm;
  }
}
