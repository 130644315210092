@import (reference) "../common/const.less";

@art_pad_s: 1em;
@art_pad_m: 4em;
@art_pad_l: 8em;
@art_pad_xl: 10em;

@img_margin_s: -1 * @art_pad_s;
@img_margin_m: -0.5 * @art_pad_m;
@img_margin_l: -0.5 * @art_pad_l;
@img_margin_xl: -0.5 * @art_pad_xl;

.extrawide-content {
  clear: both;
}

// @media screen {
//
//   .article-page,
//   .template-article-index {
//   }
// }

.article-content .extrawide-content {
  margin-bottom: 0 !important;

  .related-tiles {
    margin: 0 @img_margin_s;
    padding: 1.5em 1.5em 0;
    background-color: lighten(@c_canvas_bg, 2%);
    border-top: (2 * @pixel) solid lighten(@c_canvas_bg, 4%);
    border-bottom: (2 * @pixel) solid lighten(@c_canvas_bg, 4%);
  }
}

.template-questionform,
.template-homepage,
.category-description {

  .block-questionform {
    background-color: @c_base_light_gray;
    padding: @pixel 2em;
  }
}

.article-footer,
.article-content {
  background-color: @c_art_white;
}

.article-footer {
  padding: 1em @art_pad_s;
  margin-bottom: 3em;
  // margin-top: -2.75em;
  margin-top: -4em;
}

.imageblock {

  &.fullw {

    img {
      width: 100%;
    }
  }
}

.template-homepage,
.stripped-page-content,
.article-content {

  .block-paragraph {
    margin: 0.5em 0 0.5em;
  }

  .pullquote,
  .imageblock {
    margin: 0.5em @img_margin_s 1em;
  }

  .imageblock {

    .caption {
      margin: 0.25em 1em 2em;

      .captiontext,
      .sourcetext {
        text-align: center;
        display: block;
        font-size: 0.875em;
      }

      .captiontext {
        font-weight: @wt_caption;
      }

      .sourcetext {
        // line-height: 1.14;
        font-weight: @wt_caption_source;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }

    // .widescreen { }
    // .landscape { }

    .uncropped.portrait-mode,
    .portrait {
      max-width: 19em;
    }

    .small-portrait {
      max-width: 12em;
    }

    .uncropped.tall-mode,
    .square,
    .circular {
      max-width: 20em;
    }

    &.fullw {

      img {
        width: 100%;
      }
    }

    @media screen and (min-width: @screen_gt_micro) and (max-width: @screen_lt_medium) {

      &.floatleft.cr-small-portrait,
      &.floatright.cr-small-portrait {
        max-width: 45%;
        margin-left: 1em;
        margin-right: 1em;
      }

      &.floatleft.cr-small-portrait {
        float: left;
        margin-left: 0;
      }

      &.floatright.cr-small-portrait {
        float: right;
        margin-right: 0;
      }
    }

    &.floatleft.cr-small-portrait {
      margin-left: 0 !important;
    }

    &.floatright.cr-small-portrait {
      margin-right: 0 !important;
    }
  }

  .pullquote {
    // TODO TODOTODOTODOTODOTODOTODOTODOTODO
    border-width: 0 0 0 (8 * @pixel);
    border-style: solid;
    border-color: @c_base_cloudy-blue;
    margin: 2em 0;

    &.has-img {
      margin-bottom: 4em !important;
    }
    padding: 0 1em 0.5em;

    .quote-sym {
      width: 1.5em;
      height: 1.5em;
      color: @c_base_cloudy-blue;
    }

    blockquote {
      margin: 0;
      padding: 0;
      // padding: 0.1em 1em;
      // font-size: 1.1em;
      // font-family: @font_def;
      // font-weight: @wt_bold;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .cite {
      display: block;
      margin: 1.5em 0 0.25em;
      // text-align: right;
    }

    .timeorplace {
      display: block;
      color: @c_base_warm-gray;
    }

    .cite-image {
      border-radius: 50%;
      float: right;
      width: 5.6em;
      height: 5.6em;
      margin-top: 1.5em;
    }

    &.centered {

      blockquote,
      .cite-image-wrap {
        max-width: 55em;
      }
    }
  }
}

.stripped-page-content,
.article-content {
  @media screen {
    padding: 1em @art_pad_s;
    // margin-top: 1em;
    margin-bottom: 3em;

    h1 {
      color: @c_base_metallic_blue;
    }
  }

  .category-name {
    font-size: 1.25em;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .image-byline {
    display: block;
    text-align: center;

    font-size: 0.875em;
  }

  .file-list-block {
    margin: 1em 0;
    text-align: center;
  }

  .block-gallery .floatleft,
  .block-gallery .floatright,
  .employee-list-block {
    margin: 1em 3em;
  }
}

.template-homepage {

  .pullquote,
  .imageblock {
    margin-left: 0;
    margin-right: 0;

    &.floatleft {
      max-width: 59%;
      margin-right: auto;
      margin-left: 0;

      img,
      .imgrestrictor {
        margin-left: 0;
      }
    }

    &.floatright {
      max-width: 59%;
      margin-left: auto;
      margin-right: 0;

      img,
      .imgrestrictor {
        margin-right: 0;
      }
    }
  }
}

.article-indexbox,
.article-menutile {
  display: block;
  width: 13em;
  max-width: 100%;
  margin: 2em auto;

  .tileimage {
    width: 100%;
    height: 7em;
    background-color: @c_art_gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  h5 {
    opacity: 0.72;
    margin: 0.6em 0 0.4em;
    max-height: 3em * 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
  }

  &:hover {

    h5 {
      // border-bottom-color: @c_art_white;
      text-decoration: underline;
    }
  }

  .excerpt {
    line-height: 1.5;
    max-height: 3 * 1.5em;
    overflow: hidden;
    display: block;
  }
}

.article-indexbox {
  width: 100%;
  margin: 1em 0;
  font-weight: @wt_normal;

  .the-link {
    font-weight: @wt_bold;

    font-size: 112.5%;
  }
}

.indexbox {
  background-color: @c_art_white;
  padding: 1em;
  margin-bottom: 2em;

  &.forumbox {
    padding: 0 1em 1.5em;
    margin-bottom: 0;
  }
}

.tag-cloud,
.index-links {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  // justify-content: flex-start;
  justify-content: space-between;
  margin-bottom: 2em;

  &::after {
    content: " ";
    flex: 4 1 auto;
    // flex-grow: 4;
    // flex-shrink: 1;
    // flex-basis: auto;
    height: 1em;
    // flex-basis: 5em;
    // flex: auto;
  }
  // justify-content: space-between;
}

.index-links {
  // margin-top: -2em;
}

.tag-cloud .taglink {
  flex: 0.2 1 auto;
  margin-right: 1em;
}

.index-links .inlineblock {
  // min-width: 30%;
  display: inline-block;
  padding: 0;
  margin: 1rem 0;
  flex: 0 1 auto;

  &.title {
    margin-right: 1em;
    font-weight: @wt_normal;
  }

  .article-type {
    padding: 0 0.5rem;
    margin-right: 1em;
    // padding: 0 1em 0 0;
  }
}

.indexbox .fancylink,
.index-links .fancylink {
  margin: 2em 1em 0;
  display: inline-block;
}

// .multicol-list { }

// .pseudo-article-tile,

.article-compact-item,
.article-tile {
  margin-bottom: 5em;
  display: block;
  background-color: @c_art_white;
  box-shadow: @white_on_white_glow;
  transition: box-shadow 0.5s;

  &:active,
  &:hover {
    // background-color: pink;
    box-shadow: @white_on_white_glow_hard;

    .behavelink {
      color: @c_link_hover;
    }
  }

  .tileimage {
    height: 12em;
    background-color: @c_art_gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .front-main-article & .tileimage {
    height: 20em;
  }

  p.abovetitle,
  p.belowtitle {
    margin: 0;
    font-weight: @wt_bold;
  }

  p.abovetitle {
    margin-bottom: 0.5em;
  }

  p.belowtitle {
    margin-top: 0.5em;
  }

  .no-subtitle {
    margin: 0.75em 0;
  }

  .thetext {
    padding: 0.5em 2em @pixel;
    // padding: 0.5em 0 @pixel;
    background-color: @c_art_white;
    // min-height: @tile-text-minh;
    color: @c_def_txt;
  }

  // .article-content & {
  //   box-shadow: 0 (2 * @pixel) (2 * @pixel) @c_art_gray;
  // }
}

.article-compact-item {
  margin-bottom: 2em;

  .thetext {
    padding: 1em 2em;
    min-height: 10em;

    h6 {
      margin-bottom: 0.5em;
    }

    // .short-desc {
    //
    //   font-size: 0.9em;
    // }
  }

  .tileimage {
    height: 10em;
  }
}

.indexbox-feature,
.newsletterbox,
a.featurebanner,
.featurebox {
  margin-bottom: 2em;
}

.pseudo-article-tile {

  .thetext {
    padding: @pixel 1em;

    .headings {
      padding: 0 1em 2em;
    }

    .fancytable {
      margin-top: 0;
      margin-bottom: 1em;
    }
  }
}

@media screen and (min-width: @screen_gt_medium) and (max-width: @screen_lt_large) {

  .newsletterbox {

    h4 {
      font-size: 1.2em;
    }
  }
}

@media screen and (min-width: @screen_gt_small) {

  .article-tile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .spacer,
    .tileimage,
    .thetext {
      flex: 1 1 65%;
      box-sizing: border-box;
    }

    .tileimage {
      flex-basis: 50%;
    }

    .spacer {
      flex-grow: 0;
      flex-basis: 3em;
      height: 1em;
    }

    .odd & {
      flex-direction: row-reverse;
    }
  }

  .article-compact-item {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;

    // .spacer,

    .tileimage,
    .thetext {
      flex: 1 1 70%;
      box-sizing: border-box;
    }

    .tileimage {
      flex-basis: 32%;
      height: auto;
    }
    //
    // .spacer {
    //   flex-basis: 1em;
    // }
  }
}

@media screen and (max-width: @screen_lt_small) {

  .article-tile {

    .tileimage {
      height: 50vw;
    }

    .thetext {
      padding: 0.5em 2em @pixel;
    }
  }

  .bookmarkbox {
    display: none;
  }
}

@media screen and (min-width: @screen_gt_micro) and (max-width: @screen_lt_medium) {

  .bookmarkbox {
    display: none;
    // text-align: right;
    // width: 8em;
    // float: right;
    // margin-top: 1.5em;
  }

  .stripped-page-content,
  .article-content {

    .image-byline {
      text-align: right;
      margin-bottom: -1em;
    }
  }
}

@media screen and (max-width: @screen_lt_large) {

  .bookmarkbox {
    // text-align: left;
    // display: none;

    .bookmarkstatus {
      min-width: 3em;
    }

    b {
      display: none !important;
    }
  }
}

@media screen and (min-width: @screen_gt_medium) {

  .bookmarkbox {
    // text-align: left;
    text-align: right;
    width: 9em;

    // position: absolute;
    // top: 0.5em;
    // // left: 0;
    // right: 0;
  }
  // .indexbox { // min-height: 32em; }

  .article-indexbox {

    .tileimage {
      float: left;
      width: 10em;
    }

    .thetext {
      margin-left: 12em;
    }
  }

  .pseudo-article-tile,
  .article-tile {
    // margin-bottom: 1.5em;

    .tileimage {
      height: 16em;
    }

    .front-main-article & {

      p.abovetitle,
      p.belowtitle {
        font-size: 1.2em;
      }

      div.short-desc {
        font-size: 112.5%;
      }

      .thetext {
        // min-height: @tile-text-minh + 2em;
      }

      .tileimage {
        // height: 19em;
        height: 22.5em;
      }
    }
  }

  .pseudo-article-tile {

    .thetext {
      // min-height: @tile-text-minh + 15em;
    }
  }

  .featurebox {
    // min-height: 19em;
    min-height: 22.5em;

    .imgwrap {
      height: 11em;
      display: table-cell;
      vertical-align: middle;
    }
  }

  a.featurebanner {
    min-height: 22.5em;

    .thetext {
      // padding-top: 11.5em;
    }
  }

  .newsletterbox {
    // min-height: 10.5em;
    min-height: @tile-text-minh;
  }

  .article-footer,
  .article-content {
    padding: 1em @art_pad_m;

    .file-list-block,
    .block-gallery .floatleft,
    .block-gallery .floatright,
    .employee-list-block,
    .extrawide-content {
      margin: 2em @img_margin_m;
    }

    .pullquote,
    .imageblock {
      margin: 0.5em @img_margin_m 2em;

      // &.floatleft.cr-small-portrait,
      // &.floatright.cr-small-portrait {
      // }
    }

    .pullquote.centered {
      margin-left: 0;
      margin-right: 0;
      max-width: 90%;
    }

    .extrawide-content .related-tiles {
      margin: 0 @img_margin_m;
    }
  }

  .stripped-page-content,
  .article-content {
    position: relative;

    .image-byline {
      position: absolute;
      top: 0.5em;
      right: 2.5em;
    }

    p {
      // font-size: 1.125em;
    }

    .file-list-block {
      text-align: left;
    }

    .imageblock {

      .caption {
        margin: 0.5em 0;
        text-align: center;

        .captiontext {
          display: inline-block;
        }

        .sourcetext {
          display: inline-block;
          // float: right;
          margin-left: 1em;
          margin-top: 0.25em;
        }
      }

      &.floatleft {
        // text-align: left;
      }

      &.fullw {

        .caption {
          text-align: left;

          // .captiontext { }

          .sourcetext {
            float: right;
          }
        }
      }
    }

    .file-list-block,
    .block-gallery > div,
    .employee-list-block,
    .pullquote,
    .imageblock {
      // &.centered { }

      &.floatleft,
      &.floatright {
        max-width: 59%;
      }

      &.floatleft {
        clear: left;
        margin-right: 3em;
        float: left;
      }

      &.floatright {
        clear: right;
        margin-left: 3em;
        float: right;
      }
    }

    .pullquote.centered {
      margin-left: 0;
      margin-right: 0;
    }

    .pullquote.floatleft {
      border-width: 0 (8 * @pixel) 0 0;
      text-align: right;
      // TODO TODOTODOTODOTODOTODOTODOTODOTODO

      .cite-image {
        float: left;
      }
    }

    .block-gallery .floatleft,
    .block-gallery .floatright {
      max-width: 50%;
    }

    .file-list-block,
    .employee-list-block {

      .a-file-col,
      .an-employee-col .employee-item {
        max-width: 17em;
      }

      &.floatleft,
      &.floatright {
        max-width: 18em;
      }
    }
  }
}

@media screen and (min-width: @screen_gt_large) {

  .pseudo-article-tile,
  .article-tile {

    .tileimage {
      height: 21em;
    }
  }

  .bookmarkbox {
    // margin-top: 4.5em;

    .bookmarkstatus {
      min-width: 9em;
    }
  }

  .article-footer,
  .article-content {
    padding: 1em @art_pad_l;

    .file-list-block,
    .block-gallery .floatleft,
    .block-gallery .floatright,
    .employee-list-block,
    .extrawide-content {
      margin: 2em @img_margin_l;
    }

    .pullquote,
    .imageblock {
      margin: 0.5em @img_margin_l 2em;
    }

    .pullquote.centered {
      margin-left: 0;
      margin-right: 0;
      max-width: 80%;
    }

    .extrawide-content .related-tiles {
      margin: 0 @img_margin_l;
    }

    .image-byline {
      right: 1.5em;
    }
  }

  a.featurebanner {

    .thetext {
      // padding-top: 11.5em;
    }
  }

  .featurebox {

    .imgwrap {
      height: 11.5em;
    }
  }
}

@media screen and (min-width: @screen_gt_xlarge) {

  .article-footer,
  .article-content {
    padding: 2em @art_pad_xl;

    .file-list-block,
    .block-gallery .floatleft,
    .block-gallery .floatright,
    .employee-list-block,
    .extrawide-content {
      margin: 3em @img_margin_xl;
    }

    .pullquote,
    .imageblock {
      margin: 0.5em @img_margin_xl 3em;
    }

    .pullquote.centered {
      margin-left: 0;
      margin-right: 0;
      max-width: 70%;
    }

    .extrawide-content .related-tiles {
      margin: 0 @img_margin_xl;
    }
  }
}
@media only screen and (max-width: 600px) {
.article-compact-item {
  .thetext {
    padding: 1em 1em !important;
  }
}
.article-compact-item .tileimage {
  height: 12em;
}
.article-compact-item {
  .thetext {
    min-height: 6em;
  }
}
}
.article-list-block{ 
  p{
    margin:0;
    font-size: 2em;
    text-align: end;
    margin: 0 1em 0em 0em;
  }
}
@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  .article-compact-item{
    .tileimage {
      flex-basis: 50%;
    }
  }


  .bottom-footer{
    .padded{display:flex;}
  }
}
