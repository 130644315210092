@import (reference) "../common/const.less";

@media screen {

  .all_content_wrapper {
    min-height: 80vh;
    box-sizing: border-box;
    padding-bottom: 3em;

    // .menu-visible & {
    //   padding-bottom: 0;
    // }
  }
}

.bottom-footer {
  background-color: @c_footer_bg;

  color: @c_def_txt;
  padding: @pixel 0;
  box-sizing: border-box;
  min-height: 20vh;

  .padded {
    padding-top: 0.5em;
    padding-bottom: 2em;
  }

  h4 {
    opacity: 0.4;
    // padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    // border-bottom: 2*@pixel solid @c_menu_border;
  }

  .footer-col {
    padding: 0;
    margin-top: 1em;
    margin-bottom: 1em;

    ul,
    li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    ul {
      margin: 0 auto 2em;
    }

    a {
      font-size: 1em;

      line-height: 1.5;
      display: block;
      font-weight: @wt_bold;
    }

    li a {
      padding: 0.25em 0;
    }

    .contact-field {
      line-height: 1.25;
      margin: 0.5em 0;
    }

    .contact-address {
    }
  }
}

.footer-logo {
  max-width: 100%;
  width: 10em;
  height: 4em;
  object-fit: contain;
}

@media screen and (max-width: @screen_lt_small) {

  .bottom-footer {
    text-align: center;
  }
}

@media screen and (min-width: @screen_gt_small) {

  .bottom-footer {

    .middle,
    .first {
      padding-right: 2em;
    }
  }
}

@media screen and (max-width: @screen_lt_medium) {

  .bottom-footer {

    .bordered-col {
      border-top: @pixel solid @c_footer_border;
      padding-top: 2em;
    }
  }
}

@media screen and (min-width: @screen_gt_medium) {

  .bottom-footer {

    .middle,
    .first {
      padding-right: 2em;
    }

    .bordered-col {
      border-left: @pixel solid @c_footer_border;
      padding-left: 2em;
    }
  }
}

.footer-blue{
  background-color: #084b5f !important;
  color: white;
  a{
    color: white;
  }
}