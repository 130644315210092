.subsection-block {
  clear: both;

  .subsection-toggler {
    position: relative;
    display: block;

    svg {
      width: 2rem;
      height: 2rem;
      vertical-align: middle;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .subsection-visible,
  .subsection-hidden {
    display: none;
  }
}

@media screen and (max-width: @screen_lt_medium) {

  .subsection-block {
    border-bottom: 1px solid @c_hr_border;
    padding: 0.5em 0;

    .subs-title {
      line-height: 2rem;
      margin-bottom: 0;
      margin-top: 0;
    }

    .subsection-toggler {
    }

    .subsection-hidden {
      display: block;
    }

    .the-subsection {
      // display: none;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s;
    }

    &.expanded {

      .subsection-visible {
        display: block;
      }

      .subsection-hidden {
        display: none;
      }

      .the-subsection {
        max-height: none;
      }
    }
  }
}

@media screen and (min-width: @screen_gt_medium) {

  .subsection-block {

    .subs-title {
      line-height: 2;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
