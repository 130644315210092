@media print {

  .top-menu,
  .top-menu-spacer,
  .bottom-footer,
  .article-footer,
  .noprint {
    display: none !important;
  }

  body {
    margin: 0;
    // max-width: 210mm - 50mm;

    font-size: 12pt;
  }

  h1 {
    margin-top: 1.5rem;

    font-size: 2em;
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.6em;
  }

  h4 {
    font-size: 1.2em;
  }

  h5 {
    font-size: 1em;
  }

  h6,
  .ingress {
    font-size: 1.3em;
  }

  .infobox {
    border: 1pt solid gray;
  }

  @page {
    size: auto; // auto is the initial value
    margin: 20mm 25mm; // this affects the margin in the printer settings
  }
}
