@import (reference) "../common/const.less";

.infobox {
  position: relative;
  z-index: 1;
  padding: 2em;
  margin: 1em auto;
  max-width: 25em;

  p:empty {
    display: none;
  }

  &.gallerybox {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen {

  .infobox {
    background-color: @c_base_metallic_blue;
    color: @c_dbg_txt;

    &.dbg {
      color: #fff;
    }

    &.lbg {
      color: @c_def_txt;
    }

    &.lcolorbg {
      color: #000;
    }

    // &.scheme-navy {
    //   background-color: @c_base_navy;
    // }
    //
    // &.scheme-dark-blue {
    //   background-color: @c_base_darkblue;
    // }
    //
    // &.scheme-dark-red {
    //   background-color: @c_base_darkred;
    // }
    //
    // &.scheme-orange {
    //   background-color: @c_base_orange;
    // }
    //

    &.scheme-gray {
      background-color: darken(@c_base_pinkish_gray, 15%);
    }

    &.scheme-black {
      background-color: @c_base_granite;
    }
    //
    // &.scheme-light-navy {
    //   background-color: @c_base_lnavy;
    // }
    //

    &.scheme-light-gray {
      background-color: @c_base_light_gray;
    }

    &.scheme-white {
      background-color: #fff;
      // color: @c_def_txt;
      border: @pixel solid @c_base_lgray;
    }
  }
}

@media screen and (min-width: @screen_gt_medium) {

  .infobox {

    &.centered {
      max-width: 11 * @colBaseUnit;
      margin: 0 auto;
    }

    &.fullw {
      max-width: 12 * @colBaseUnit;
    }

    &.floatleft,
    &.floatright {
      z-index: 1;
      position: relative;
    }

    &.floatleft {
      max-width: 4 * @colBaseUnit;
      float: left;
      clear: left;
      margin-right: 5em;
    }

    &.floatright {
      max-width: 4 * @colBaseUnit;
      float: right;
      clear: right;
      margin-left: 5em;
    }
  }
}

@media screen and (min-width: @screen_gt_large) {

  .infobox {

    &.centered {
      max-width: 8 * @colBaseUnit;
      margin: 0 auto;
    }

    // &.fullw { }

    &.floatleft {
      max-width: 3.5 * @colBaseUnit;
      // float: left;
      // margin-right: 5em;
    }

    &.floatright {
      max-width: 3.5 * @colBaseUnit;
      // float: right;
      // margin-left: 5em;
    }
  }
}
