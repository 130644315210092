html,
body {
  margin: 0;
  padding: 0;
}

.main-content {
  min-height: 70vh;
}

.imgrestrictor {
  margin: 0 auto;
  max-width: 100%;
}

.svg-imgrestrictor {
  margin: 0 auto;
  max-width: 100%;

  img {
    width: 100%;
  }

  object {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

img {
  max-width: 100%;
  height: auto;

  .circular & {
    border-radius: 50%;
  }
}

.mainimg img {
  display: block;
  margin: 0 auto;

  &.tall-mode {
    max-height: 80vh;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  &.portrait-mode {
    max-height: 70vh;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

.responsive-object {
  position: relative;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
