@import (reference) "const.less";

body,
html {
  margin: 0;
  padding: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.clearfix {
  clear: both;
}

.row {
  padding: @pixel 0;

  .row.nested &,
  &.unpadded {
    padding: 0;
  }
}

.screen-reader-text {
  position: absolute;
  left: -10000em;
  top: -10000em;
}

.unpadded {
  padding: 0 !important;
}

.rowClearHack() {
  content: "_";
  clear: both;
  height: 0;
  display: block;
  overflow: hidden;
}

.row::after {
  .rowClearHack();
}

@media screen and (max-width: @screen_lt_small) {

  .mobrow::after {
    .rowClearHack();
  }
}

@media screen and (min-width: @screen_gt_small) {

  .bigrow::after {
    .rowClearHack();
  }
}
