.fancylink-wrap {

  &.no-margin {
    margin: -1rem 0 0;
  }

  &.margin-above,
  &.margin-below,
  &.margin-both {
    margin: 2rem 0;
  }

  &.margin-above {
    margin-bottom: 0;
  }

  &.margin-below {
    margin-top: -1rem;
  }
}

@media screen and (min-width: @screen_gt_large) {

  .fancylink-wrap {

    &.margin-above,
    &.margin-below,
    &.margin-both {
      margin: 3rem 0;
    }

    &.margin-above {
      margin-bottom: 0;
    }

    &.margin-below {
      margin-top: -1rem;
    }
  }
}
