@import (reference) "../common/const.less";

html,
body {
  font-size: 100% * @font_size_scale;
  letter-spacing: @letter_spacing_3;
  background-color: @c_canvas_bg;
  color: @c_canvas_txt;
  font-family: @font_def;
  line-height: 1.5;
  //
}

.footer-col,
.rich-text {

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {

    &:first-child {
      margin-top: 0;
    }

    // &:last-child {
    //   margin-bottom: 0;
    // }
  }
}

// .rich-text {
//   margin-bottom: 2em;
//
//   &:first-child {
//     margin
//   }
// }

// .template-homepage {
//
//   .block-lead,
//   .block-paragraph {
//     padding: 2em 0;
//   }
// }

.svg-text-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: bottom;

  &.small {
    width: 1em;
  }
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.29;

  .svg-text-icon {
    height: 1.29em;
    width: 1.29em;
  }
}

.text-muted {
  opacity: 0.8;
}

h1 {
  font-size: 2.625em;
  font-family: @font_def;
  font-weight: @wt_h1;
  margin: 2.5rem 0 1rem;
  letter-spacing: @letter_spacing_3;
}

.block-lead,
.ingress,
h2 {
  font-size: 2em;
  font-family: @font_def;
  font-weight: @wt_h2;
  margin: 1em 0 1rem;
  letter-spacing: @letter_spacing_3;
}

.block-lead,
.ingress {
  font-size: 1.5em;
}

// .template-homepage h2 {
//   margin-bottom: 1em;
// }

h3 {
  font-size: 1.75em;
  font-family: @font_def;
  font-weight: @wt_h3;
  margin: 1em 0 1rem;
  letter-spacing: 0;
}

h4 {
  font-size: 1.5em;
  font-family: @font_def;
  font-weight: @wt_h4;
  margin: 1em 0 1rem;
  letter-spacing: @letter_spacing_3;
}

h5 {
  font-size: 112.5%;
  // text-transform: uppercase;
  font-family: @font_def;
  font-weight: @wt_h5;
  margin: 2em 0 0.5rem;

  & + p {
    margin-top: 0;
  }
}

h6 {
  font-size: 1em;
  font-family: @font_def;
  font-weight: @wt_h6;
  margin: 2em 0 0.5rem;

  & + p {
    margin-top: 0;
  }
}

p {
  margin: 1.5em 0;
}

.larger-richtext {
  // font-size: 1.5em;
  font-size: 1.25em;
  font-style: normal;
  font-family: @font_def;
  font-weight: @wt_h6;
  margin: 1em 0;
  // padding-bottom: 1em;
}

b,
strong {
  font-weight: @wt_bold;
  letter-spacing: @letter_spacing_4;
}

i,
em {
  font-style: italic;
}

small {
  font-size: 0.875em;
}

.first-published-at,
.byline {
  margin-top: 0.5em;
  margin-bottom: 1em;
  display: inline-block;
  // margin-right: 2em;
}

.page-first-published-at {
  padding: 1em 0.5em 0;
  display: inline-block;
}

.aligntop {
  margin-top: 0 !important;
}

.alignbot {
  margin-bottom: 0 !important;
}

.shareBtnLink {
  display: inline-block;
  padding: 0.1em 0.2em;
  margin: 0.5em 1em;
  cursor: pointer;
}

a {

  img {
    border: 0 none;
    outline: 0;
  }

  color: @c_link;
  text-decoration: none;
  font-weight: @wt_link;
  letter-spacing: @letter_spacing_4;

  &:visited {
    color: @c_link_visit;
  }

  &:active,
  &:hover {
    text-decoration: none;
    color: @c_link_hover;
  }
}

.post-content {

  a {
    color: @c_article_link;

    &:visited {
      color: @c_article_link_visit;
    }

    &:hover {
      color: @c_article_link_hover;
      text-decoration: underline;
    }
  }

  .nounderline a {
    text-decoration: none;
  }
}

.dbg a {
  color: @c_link_dbg;

  &:visited {
    color: @c_link_dbg_visit;
  }

  &:hover {
    color: @c_link_dbg_hover;
  }
}

a.taglink {
  background-color: @c_link;
  color: @c_dbg_txt;
  border-radius: (4 * @pixel);
  font-weight: @wt_bold;
  display: inline-block;
  padding: 0 0.7em;
  line-height: 2;
  min-width: 4em;
  text-align: center;
  margin: 0.5em 0.1em;

  &:active,
  &:hover {
    background-color: @c_link_hover;
    color: @c_dbg_txt;
  }
}

@media screen and (max-width: @screen_lt_mob) {

  body {
    font-size: 100%;
    // font-size: 93.75%;
  }
}

@media screen and (max-width: @screen_micro) {

  body {
    // font-size: 87.5%;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media screen and (max-width: @screen_lt_small) {

  .tiny-left {
    text-align: left;
  }

  .tiny-center {
    text-align: center;
  }

  .tiny-right {
    text-align: right;
  }
}

@media screen and (max-width: @screen_lt_medium) {

  .small-left {
    text-align: left;
  }

  .small-center {
    text-align: center;
  }

  .small-right {
    text-align: right;
  }

  h1 {
    margin-top: 1.5rem;
    font-size: 2em;
    letter-spacing: @letter_spacing_2;
  }

  .block-lead,
  .ingress,
  h2 {
    font-size: 1.375em;
    letter-spacing: @letter_spacing_2;
  }

  .block-lead,
  .ingress {
    font-size: 1.25em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1.125em;
  }

  h5 {
    font-size: 1em;
  }

  .larger-richtext {
    font-size: 1.1em;
  }

  // h6,
  // .block-lead,
  // .ingress {
  //   font-size: 1.2em;
  // }
}

@media screen and (min-width: @screen_gt_medium) {

  .first-published-at {
    margin-right: 2em;
  }

  .big-left {
    text-align: left;
  }

  .big-center {
    text-align: center;
  }

  .big-right {
    text-align: right;
  }
}

@media screen and (min-width: @screen_gt_xlarge) {

  body {
  }
}

@media screen and (min-width: @screen_gt_xxlarge) {

  body {
    font-size: 112.5% * @font_size_scale;
  }
}
