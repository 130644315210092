@import (reference) "../common/const.less";

.toc-box {
  margin: 2em 0;

  &.bordered {
    border-bottom: solid 2*@pixel @c_hr_border;
    padding-bottom: 1em;

    h3 {
      border-bottom: solid 2*@pixel @c_hr_border;
      padding-bottom: 0.5rem;
    }
  }

  h3 {
    margin: 0 0 1rem;
  }

  .toc-el {
    display: block;

    font-size: 1.125em;
    padding: 0.5rem;
    line-height: 1.5rem;
    font-weight: @wt_bold;

    // &.toc-chapter { }

    &.toc-subsection {
      font-weight: @wt_normal;
      font-size: 1em;
    }
  }

  .toc-subsection + .toc-chapter {
    margin-top: 0.5em;
  }

  &.inline {
    margin: 0 0 0.5em;

    .toc-el {
      display: inline-block;
      padding: 0.5em 1em;
    }
  }
}

.to-top-link {
  display: none;
}

@media screen and (min-width: @screen_gt_medium) {

  .has-toc .to-top-link {
    display: block;
  }

  .to-top-link {
    position: fixed;
    top: 4.5em;
    right: 1em;
    z-index: 100;

    a {
      display: inline-block;
      font-size: 0.875em;
      padding: 0 0.5em;
      font-size: 1rem;
      vertical-align: middle;
    }
  }

  .toc-box {
    margin: 1.5em auto 3em;
    min-width: 17em;

    &.floatleft,
    &.floatright {
      z-index: 1;
      position: relative;
    }

    &.centered {
      min-width: 20em;
      max-width: 50%;
    }

    &.floatleft {
      max-width: 60%;
      float: left;
      clear: left;
      margin-left: 0;
      margin-right: 3em;
    }

    &.floatright {
      max-width: 60%;
      float: right;
      clear: right;
      margin-right: 0;
      margin-left: 3em;
    }
  }
}

@media screen and (max-width: @screen_lt_menu_cutoff) {

  .to-top-link {
    top: 1.5em;
    z-index: 8;

    a span {
      display: none;
    }
  }
}
