@import (reference) "../common/const.less";

button,
select,
textarea,
input {
  box-sizing: border-box;
  font-family: @font_def;

  font-size: 100%;
}

textarea {
  display: block;
  width: 100%;
  padding: 1em;
}

input[type="button"],
input[type="submit"],
a.button,
.button,
button {
  padding: 0.5em 1em;
  margin: 1em auto;

  background-color: @c_button_bg;
  color: @c_button_txt;
  border: 2*@pixel solid @c_button_bg;
  display: inline-block;

  &.block-button {
    display: block;
    width: 100%;
  }

  &.disabled,
  &:disabled {
    background-color: @c_button_disabled;
    color: @c_button_disabled_txt;
    border-color: @c_button_disabled;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: @c_button_active_bg;
    color: @c_button_active_txt;
    border-color: @c_button_active_bg;
  }
}

a {

  &:hover,
  &:active,
  &:focus {

    .button {
      background-color: @c_button_active_bg;
      color: @c_button_active_txt;
      border-color: @c_button_active_bg;
    }
  }
}

.errorlist {
  margin: 0.25em 0;
  color: @c_form_error;
}
