@import (reference) "../common/const.less";

.flex-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  // align-items: stretch;

  .flex-gallery-cell {
    flex: 1 1 auto;

    .infobox {
      box-sizing: border-box;
      height: 100%;
    }
  }
}

.gallery-cell {
  // max-width: 24em;
  margin: 0 auto;
  padding-top: 1em;

  .auto2col > & {
    max-width: 32em;
  }

  .desc {
    font-size: 1em;
    margin-top: 0.25em;
    text-align: center;
  }

  .imageblock {
    margin: 0 !important;
  }

  .gallery-item-title {
    margin-top: 0.5rem;
    display: block;
    text-align: center;
  }

  img {
    width: 100%;
    object-fit: scale-down;
  }

  // .widescreen { }
  // .landscape { }

  .uncropped.portrait-mode,
  .portrait {
    max-width: 15em;
  }

  .uncropped.tall-mode,
  .square,
  .circular {
    max-width: 17em;
  }
}

.block-gallery > div {
  margin: 1em auto 2em;
}

@media screen and (min-width: @screen_gt_small) {

  .flex-gallery {
    // justify-content: space-around;

    &.max-3cols .flex-gallery-cell,
    &.max-2cols .flex-gallery-cell {
      margin: 0 0 1em;
      flex-basis: 48%;
      flex-grow: 0;
    }
  }
}
@media screen and (min-width: @screen_gt_large) {

  .flex-gallery {

    &.max-3cols .flex-gallery-cell {
      flex-basis: 31%;
    }

    &.max-2cols .flex-gallery-cell {
      flex-basis: 48%;
    }
  }
}

.block-gallery {
  // background-color: pink;
}
